import React from "react";

export default function MoneyIcon({ active }) {
	return (
		<>
			{active ? (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						opacity="0.4"
						d="M15.675 5.20807H16.8084C16.6501 4.98307 16.4834 4.77474 16.3084 4.56641L15.675 5.20807Z"
						fill="#0182AB"
					/>
					<path
						opacity="0.4"
						d="M15.4334 3.68359C15.2251 3.50859 15.0167 3.34193 14.7917 3.18359V4.31693L15.4334 3.68359Z"
						fill="#0182AB"
					/>
					<path
						opacity="0.4"
						d="M14.1667 6.45768C14.0834 6.45768 14.0083 6.44102 13.925 6.40768C13.775 6.34102 13.65 6.22435 13.5833 6.06601C13.55 5.99101 13.5333 5.90768 13.5333 5.82435V2.49101C13.5333 2.48268 13.5417 2.47435 13.5417 2.45768C12.4667 1.95768 11.2667 1.66602 10 1.66602C5.40002 1.66602 1.66669 5.39935 1.66669 9.99935C1.66669 14.5993 5.40002 18.3327 10 18.3327C14.6 18.3327 18.3334 14.5993 18.3334 9.99935C18.3334 8.73268 18.0417 7.53268 17.5334 6.44935C17.525 6.44935 17.5167 6.45768 17.5 6.45768H14.1667Z"
						fill="#0182AB"
					/>
					<path
						d="M16.3167 4.56589L18.775 2.10755C19.0167 1.86588 19.0167 1.46589 18.775 1.22422C18.5334 0.982552 18.1334 0.982552 17.8917 1.22422L15.4333 3.68255C15.75 3.95755 16.0417 4.25755 16.3167 4.56589Z"
						fill="#0182AB"
					/>
					<path
						d="M14.7918 2.5C14.7918 2.15833 14.5085 1.875 14.1668 1.875C13.8335 1.875 13.5668 2.14167 13.5502 2.46667C13.9835 2.675 14.4002 2.90833 14.7918 3.18333V2.5Z"
						fill="#0182AB"
					/>
					<path
						d="M18.125 5.83398C18.125 5.49232 17.8417 5.20898 17.5 5.20898H16.8083C17.0833 5.60065 17.325 6.01732 17.525 6.45065C17.8583 6.43398 18.125 6.16732 18.125 5.83398Z"
						fill="#0182AB"
					/>
					<path
						d="M11.4584 9.85L10.625 9.55833V7.70833H10.6917C11.1167 7.70833 11.4584 8.08333 11.4584 8.54167C11.4584 8.88333 11.7417 9.16667 12.0834 9.16667C12.425 9.16667 12.7084 8.88333 12.7084 8.54167C12.7084 7.39167 11.8083 6.45833 10.6917 6.45833H10.625V6.25C10.625 5.90833 10.3417 5.625 10 5.625C9.65835 5.625 9.37502 5.90833 9.37502 6.25V6.45833H9.12503C8.1167 6.45833 7.29169 7.30833 7.29169 8.35833C7.29169 9.575 8.00002 9.96667 8.54169 10.1583L9.37502 10.45V12.3H9.30836C8.88336 12.3 8.54169 11.925 8.54169 11.4667C8.54169 11.125 8.25835 10.8417 7.91669 10.8417C7.57502 10.8417 7.29169 11.125 7.29169 11.4667C7.29169 12.6167 8.1917 13.55 9.30836 13.55H9.37502V13.7583C9.37502 14.1 9.65835 14.3833 10 14.3833C10.3417 14.3833 10.625 14.1 10.625 13.7583V13.55H10.875C11.8833 13.55 12.7084 12.7 12.7084 11.65C12.7084 10.425 12 10.0333 11.4584 9.85ZM8.95001 8.96667C8.66668 8.86667 8.54169 8.80833 8.54169 8.35C8.54169 7.99166 8.80836 7.7 9.12503 7.7H9.37502V9.10833L8.95001 8.96667ZM10.875 12.2917H10.625V10.8833L11.05 11.0333C11.3334 11.1333 11.4584 11.1917 11.4584 11.65C11.4584 12 11.1917 12.2917 10.875 12.2917Z"
						fill="#F58E30"
					/>
				</svg>
			) : (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M7.91663 11.4585C7.91663 12.2669 8.54164 12.9169 9.3083 12.9169H10.8749C11.5416 12.9169 12.0833 12.3502 12.0833 11.6419C12.0833 10.8835 11.75 10.6085 11.2583 10.4335L8.74996 9.55853C8.25829 9.38353 7.92497 9.11686 7.92497 8.35019C7.92497 7.65019 8.46662 7.0752 9.13329 7.0752H10.7C11.4666 7.0752 12.0916 7.7252 12.0916 8.53353"
						stroke="#939393"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M10 6.25V13.75"
						stroke="#939393"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M18.3333 10.0003C18.3333 14.6003 14.6 18.3337 9.99996 18.3337C5.39996 18.3337 1.66663 14.6003 1.66663 10.0003C1.66663 5.40033 5.39996 1.66699 9.99996 1.66699"
						stroke="#939393"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M14.1666 2.5V5.83333H17.5"
						stroke="#939393"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M18.3333 1.66699L14.1666 5.83366"
						stroke="#939393"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			)}
		</>
	);
}
