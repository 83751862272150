import React from "react";
import GlobalButton from "@/components/ui/GlobalButton";

export default function ProCard() {
	const handleNavigate = (e) => {
		e.preventDefault();
		window.open("https://usecrafter.com/join-waitlist", "_blank");
	};

	const proDetails = [
		"Enhanced automation capabilities, with your sprints and backlog, streamlining your project management like never before.",
		"Crafter Pro will be an improvement of all the amazing features we currently offer.",
		"Be the first to know when it launches.",
	];
	return (
		<div className="flex flex-col gap-5 h-full px-4 py-6 w-full fullBorder5 rounded-lg cardBg leading-[25.6px]">
			<div className="flex flex-col gap-3 w-full items-start">
				<h1 className="text-[24px] font-medium flex flex-col gap-1 tracking-[-0.5px]">
					Crafter Pro
					<span className="text-error-2 font-semibold text-base">
						(Coming soon)
					</span>
				</h1>
				<p className="text-base text-white-2">
					We are working on this and it is coming in soon.
				</p>
			</div>

			<div className="flex flex-col gap-8">
				<div className="flex-end mt-auto">
					<GlobalButton
						onClick={handleNavigate}
						variant={"secondary"}
						size={"md"}
						state={"default"}>
						Join waitlist
					</GlobalButton>
				</div>
				<ul className="w-full text-base flex flex-col gap-5 list-disc list-outside pl-5 text-white-2">
					{proDetails.map((proDetail, index) => (
						<li>{proDetail}</li>
					))}
				</ul>
			</div>
		</div>
	);
}
