import React from "react";

export default function DocIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3.41702 0.00415039C2.36627 0.00415039 1.51465 0.856525 1.51465 1.9069V22.0973C1.51465 23.1484 2.36665 24 3.41702 24H20.5826C21.633 24 22.4854 23.1484 22.4854 22.0973V7.6204L15.3964 0.00415039H3.41702Z"
				fill="#3C8CEA"
			/>
			<path
				d="M5.29993 14.0397H13.3864V14.8118H5.29993V14.0397ZM5.29993 15.84H13.3864V16.6114H5.29993V15.84ZM5.29993 17.6404H13.3864V18.4118H5.29993V17.6404ZM5.29993 19.4404H9.8873V20.2122H5.29993V19.4404Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M22.485 7.64135V8.01635H17.6853C17.6853 8.01635 15.3183 7.54385 15.387 5.50122C15.387 5.50122 15.465 7.64135 17.6385 7.64135H22.485Z"
				fill="#2D6FE4"
			/>
			<path
				opacity="0.5"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M15.3967 0.00939941V5.46977C15.3967 6.09077 15.8107 7.6414 17.6857 7.6414H22.4854L15.3967 0.00939941Z"
				fill="white"
			/>
		</svg>
	);
}
