import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import GlobalButton from "@/components/ui/GlobalButton";
import InputField from "@/components/ui/InputField";
import { Link } from "react-router-dom";
import WebAppService from "@/services/WebAppService";
import useAuthStore from "@/store/Auth";
import TermsModal from "@/components/modals/TermsModal";
import PrivacyModal from "@/components/modals/PrivacyModal";

export default function LoginForm({ setSubmissionSuccess, onEmailEntered }) {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formValid, setFormValid] = useState(false);
	const [formData, setFormData] = useState({
		purpose: "authorization",
		email: "",
	});
	const [showTerms, setShowTerms] = useState(false);
	const [showPolicy, setShowPolicy] = useState(false);
	const [buttonSize, setButtonSize] = useState("md");

	const openTerms = () => {
		setShowTerms(true);
	};

	const closeTerms = () => {
		setShowTerms(false);
	};
	const openPolicy = () => {
		setShowPolicy(true);
	};

	const closePolicy = () => {
		setShowPolicy(false);
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
		if (name === "email") {
			onEmailEntered(value); // Pass the entered email to the parent component
		}
	};

	const loginMutation = useMutation((data) => WebAppService.getOTP(data), {
		onSuccess: () => {
			setIsSubmitting(false);
			// ("OTP request successful");
			setSubmissionSuccess(true);
		},
		onError: (error) => {
			setIsSubmitting(false);
			// console.error("OTP request failed:", error);
			throw error;
		},
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (formValid) {
			setIsSubmitting(true);
			loginMutation.mutate(formData);
		}
	};

	useEffect(() => {
		const { email } = formData;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isValid = email.trim() !== "" && emailRegex.test(email);
		setFormValid(isValid);
	}, [formData]);

	useEffect(() => {
		// Determine the screen size and set button size accordingly
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setButtonSize("sm");
			} else if (window.innerWidth > 1024) {
				setButtonSize("lg");
			} else {
				setButtonSize("md");
			}
		};

		// Initial call to set the button size
		handleResize();

		// Event listener for window resize
		window.addEventListener("resize", handleResize);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="flex flex-col gap-10 items-center justify-center h-full py-10">
			<div className="flex m-auto border-0.5 border-gray-2 h-fit px-5 md:px-10 lg:px-10 py-16 md:py-10 lg:py-16 w-[95%] md:w-[70%] lg:w-[70%] xl:w-[60%] xl:max-w-[650px] rounded-xl fullBorder4">
				<div className="flex flex-col gap-7 text-left lg:text-center w-full lg:mx-auto">
					<div className="flex flex-col gap-2">
						<h1 className="text-center text-white-1 font-semibold text-[18px] md:text-[20px] lg:text-[24px]">
							Welcome back!
						</h1>
					</div>
					<form
						onSubmit={handleSubmit}
						className="flex flex-col gap-6 w-full">
						<div className="flex flex-col gap-6">
							<InputField
								name="email"
								label="Company Email"
								type="email"
								placeholder="johndoe@usecrafter.com"
								isRequired={true}
								value={formData.email}
								onChange={handleInputChange}
							/>
						</div>

						<GlobalButton
							variant="secondary"
							size={buttonSize}
							state={isSubmitting || !formValid ? "inactive" : "default"}
							type="submit"
							disabled={!formValid || isSubmitting}>
							{isSubmitting ? "Requesting..." : "Request access code"}
						</GlobalButton>
					</form>
					<p className="text-center text-gray-6 text-[12px] md:text-[14px] lg:text-[16px]">
						Don’t have an account?{" "}
						<Link
							to="/signup"
							className="text-blue-2 underline underline-offset-1">
							Create one
						</Link>
					</p>
				</div>
			</div>
			<p className="w-[90%] text-center mb-5 text-xs md:text-sm lg:text-sm">
				By logging in, you accept our{" "}
				<span
					onClick={openTerms}
					className="cursor-pointer text-blue-4 font-semibold">
					Terms of service
				</span>{" "}
				and{" "}
				<span
					onClick={openPolicy}
					className="cursor-pointer text-blue-4 font-semibold">
					Privacy Policy
				</span>
			</p>

			{showTerms && <TermsModal onClose={closeTerms} />}
			{showPolicy && <PrivacyModal onClose={closePolicy} />}
		</div>
	);
}
