import React, { useEffect, useState } from "react";
import ProjectsIcon from "@/icons/ProjectsIcon";
import { useNavigate } from "react-router";

export default function Project3({ project }) {
	const [showProjectModal, setShowProjectModal] = useState(false);
	const navigate = useNavigate();

	const handleNavigate = () => {
		navigate(`/projects/${project.id}`);
	};

	const tasks = project?.tasks;

	const createdAtDate = new Date(project.created_at);
	const now = new Date();
	const timeDifference = now - createdAtDate;
	const timeAgo = getTimeAgoString(timeDifference);

	const projectName = project?.name;

	const lastDotIndex = projectName.lastIndexOf(".");

	// Check if "." exists in the string and get the substring before it
	const formattedProjectName =
		lastDotIndex !== -1 ? projectName.substring(0, lastDotIndex) : projectName;

	function getTimeAgoString(milliseconds) {
		const seconds = Math.floor(milliseconds / 1000);
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		if (days > 0) {
			return `${days} day${days !== 1 ? "s" : ""} ago`;
		} else if (hours > 0) {
			return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
		} else if (minutes > 0) {
			return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
		} else {
			return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
		}
	}

	return (
		<div
			onClick={handleNavigate}
			className={`${
				tasks.length <= 0
					? "text-gray-2 opacity-100 bg-black-4"
					: " text-gray-4 projectBg hover:text-gray-5 "
			} flex cursor-pointer items-center justify-between px-5 py-3 rounded-md transition-colors duration-300`}>
			<div className="flex gap-2">
				{/* <p>{project.label}</p> */}
				<ProjectsIcon />

				<p className="text-[16px]">{formattedProjectName}</p>
			</div>
			<div className="flex items-center gap-2 text-[12px]">
				<p>Created {timeAgo}</p>
				{/* <MoreIcon /> */}
			</div>
		</div>
	);
}
