import React, { useState, useEffect } from "react";
import EyeIcon from "@/icons/EyeIcon";
import TrashIconSm from "@/icons/TrashIconSm";
import WebAppService from "@/services/WebAppService";
import ConfirmDelete from "@/components/modals/ConfirmDelete";

export default function TicketFull({
	task,
	tasks,
	taskId,
	index,
	setShowTicketPage,
	onDeleteTicket,
}) {
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const handleTicketClick = () => {
		setShowTicketPage(index);
	};

	const handleConfirmDelete = () => {
		setConfirmDelete(true);
	};

	const handleCloseConfirmDelete = () => {
		setConfirmDelete(false);
	};

	useEffect(() => {
		// console.log("Tasks prop updated:", tasks);
	}, [tasks]);

	const handleTicketDelete = async () => {
		setIsDeleting(true);

		try {
			const updatedTasks = tasks.filter((_, idx) => idx !== index);

			// console.log("Tasks being sent to backend:", updatedTasks);

			// if (updatedTasks.length === 0) {
			// 	throw new Error("Tasks array cannot be empty");
			// }

			setTimeout(() => {
				onDeleteTicket();
			}, 2000);
			await WebAppService.saveEditedProject(taskId, { tasks: updatedTasks });
		} catch (error) {
			console.error("Error deleting ticket:", error.message);
		}

		setIsDeleting(false);
		setConfirmDelete(false);
	};

	return (
		<div>
			<div className="h-fit overflow-auto">
				<div
					onClick={handleTicketClick}
					className="cursor-pointer text-gray-1 hover:text-gray-5 transition-colors duration-300 px-[15px] py-[10px] flex items-center gap-4 justify-between w-full border-0.5 border-gray-2/50 rounded-md bg4">
					<p className="text-[14px]  w-full ">{task.title}</p>

					<div className="flex gap-2 items-start justify-end w-2/5">
						<button
							onClick={handleTicketClick}
							className="flex items-center text-xs rounded-md gap-2 py-2 px-2 hover:bg-black-1 text-blue-4">
							<EyeIcon />
							View
						</button>
						<button
							// onClick={handleConfirmDelete}
							onClick={(event) => {
								event.stopPropagation();
								handleConfirmDelete();
							}}
							className="flex items-center text-xs rounded-md gap-2 py-2 px-2 hover:bg-black-1 text-error-2">
							<TrashIconSm />
							Delete ticket
						</button>
					</div>
				</div>
			</div>
			{confirmDelete && (
				<ConfirmDelete
					type={`ticket`}
					isDeleting={isDeleting}
					onDelete={handleTicketDelete}
					onClose={handleCloseConfirmDelete}
				/>
			)}
		</div>
	);
}
