import React from "react";

export default function Activity({ active }) {
	return (
		<>
			{active ? (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M16.1163 12.0748L15.283 10.6915C15.108 10.3832 14.9496 9.79984 14.9496 9.45817V7.34984C14.9496 4.63317 12.7413 2.4165 10.0163 2.4165C7.29129 2.4165 5.08296 4.63317 5.08296 7.34984V9.45817C5.08296 9.79984 4.92463 10.3832 4.74963 10.6832L3.90796 12.0748C3.57463 12.6332 3.49963 13.2498 3.70796 13.8165C3.90796 14.3748 4.38296 14.8082 4.99963 15.0165C6.61629 15.5665 8.31629 15.8332 10.0163 15.8332C11.7163 15.8332 13.4163 15.5665 15.033 15.0248C15.6163 14.8332 16.0663 14.3915 16.283 13.8165C16.4996 13.2415 16.4413 12.6082 16.1163 12.0748Z"
						fill="#D4D4D4"
					/>
					<path
						d="M11.8743 2.7665C11.2993 2.5415 10.6743 2.4165 10.016 2.4165C9.36602 2.4165 8.74102 2.53317 8.16602 2.7665C8.52435 2.0915 9.23268 1.6665 10.016 1.6665C10.8077 1.6665 11.5077 2.0915 11.8743 2.7665Z"
						fill="#F58E30"
					/>
					<path
						d="M12.3587 16.6748C12.0087 17.6415 11.0837 18.3332 10.0004 18.3332C9.34206 18.3332 8.69206 18.0665 8.23372 17.5915C7.96706 17.3415 7.76706 17.0082 7.65039 16.6665C7.75872 16.6832 7.86706 16.6915 7.98372 16.7082C8.17539 16.7332 8.37539 16.7582 8.57539 16.7748C9.05039 16.8165 9.53372 16.8415 10.0171 16.8415C10.4921 16.8415 10.9671 16.8165 11.4337 16.7748C11.6087 16.7582 11.7837 16.7498 11.9504 16.7248C12.0837 16.7082 12.2171 16.6915 12.3587 16.6748Z"
						fill="#0182AB"
					/>
				</svg>
			) : (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M10.0155 2.42505C7.25719 2.42505 5.01552 4.66672 5.01552 7.42505V9.83338C5.01552 10.3417 4.79885 11.1167 4.54052 11.55L3.58219 13.1417C2.99052 14.125 3.39885 15.2167 4.48219 15.5834C8.07385 16.7834 11.9489 16.7834 15.5405 15.5834C16.5489 15.25 16.9905 14.0584 16.4405 13.1417L15.4822 11.55C15.2322 11.1167 15.0155 10.3417 15.0155 9.83338V7.42505C15.0155 4.67505 12.7655 2.42505 10.0155 2.42505Z"
						stroke="#A6A6A6"
						stroke-width="1.5"
						stroke-miterlimit="10"
						stroke-linecap="round"
					/>
					<path
						d="M11.5579 2.6667C11.2996 2.5917 11.0329 2.53337 10.7579 2.50003C9.95794 2.40003 9.19128 2.45837 8.47461 2.6667C8.71628 2.05003 9.31628 1.6167 10.0163 1.6167C10.7163 1.6167 11.3163 2.05003 11.5579 2.6667Z"
						stroke="#A6A6A6"
						stroke-width="1.5"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12.5156 15.8833C12.5156 17.2583 11.3906 18.3833 10.0156 18.3833C9.33229 18.3833 8.69896 18.1 8.24896 17.65C7.79896 17.2 7.51562 16.5666 7.51562 15.8833"
						stroke="#A6A6A6"
						stroke-width="1.5"
						stroke-miterlimit="10"
					/>
				</svg>
			)}
		</>
	);
}
