import React, { useState } from "react";
import GlobalButton from "@/components/ui/GlobalButton";
import UsedInputField from "@/components/ui/UsedInputField";
import useAuthStore from "@/store/Auth";
import JiraIntegrationForm from "./JiraIntegrationForm";
import DashboardWrapper from "@/components/ui/DashboardWrapper";

export default function AlreadyConfigured({ onCancel }) {
	const authStore = useAuthStore();
	const organizationDetails = authStore.organization;
	const [showJiraForm, setShowJiraForm] = useState(false);

	const handleCancel = () => {
		onCancel();
	};

	const handleEditJiraConfig = () => {
		setShowJiraForm(true);
	};
	return (
		<>
			{!showJiraForm ? (
				<div className="h-screen">
					<DashboardWrapper>
						<div className="flex innerFrameBg flex-col gap-8 w-full h-full p-20 mt-8 border border-gray-2/30 rounded-md">
							<div
								id="already"
								className="flex flex-col gap-10 w-full  lg:w-[65%] 2xl:max-w-[50%] h-full p-8 mx-auto  rounded-md">
								<div className="flex items-center justify-center w-full ">
									<div className="flex flex-col gap-2 items-center">
										<h3 className="font-semibold text-[20px] text-white-1">
											Your organization is already synced on Crafter
										</h3>
										<p className="text-[14px] text-gray-4 text-center w-4/5 leading-[21px]">
											Someone in your organization has set up Jira. You can
											override this setting by editing and submitting a new Jira
											configuration.
										</p>
									</div>
								</div>
								<form className="w-full flex flex-col gap-4">
									<div className="flex flex-col gap-8 text-gray-4">
										<UsedInputField
											label={"Organisation's Jira Admin Email"}
											value={organizationDetails.jira_admin_email}
										/>
										<UsedInputField
											label={`Organization’s Jira URL`}
											value={organizationDetails.jira_url}
										/>
										<UsedInputField
											label={"Organization’s Jira API key"}
											value={"***************************"}
										/>
									</div>

									<div className="flex gap-2 w-full justify-end mt-3">
										<div className="min-w-32">
											<GlobalButton
												variant={"blue"}
												size={"sm"}
												state={"default"}
												onClick={handleEditJiraConfig}>
												Edit Jira Config
											</GlobalButton>
										</div>
										<div className="min-w-32">
											<GlobalButton
												variant={"stroke"}
												size={"sm"}
												state={"default"}
												onClick={handleCancel}>
												Back
											</GlobalButton>
										</div>
									</div>
								</form>
							</div>
						</div>
					</DashboardWrapper>
				</div>
			) : (
				<JiraIntegrationForm />
			)}
		</>
	);
}
