import React from "react";
import CloseIcon from "@/icons/CloseIcon";

export default function ErrorToast({ message }) {
	return (
		<div className="w-[30%] absolute bg5 top-10 right-[10px] z-[1000]">
			<div className="text-gray-4 flex items-center gap-5 w-[100%] z-50 border-0.5 border-gray-5/50 text-base h-fit px-4 py-8 shadow-lg rounded-md">
				<div>
					<span className="cursor-pointer">
						<CloseIcon />
					</span>
				</div>
				<div>{message}</div>
			</div>
		</div>
	);
}
