import React from "react";

export default function UsedInputField({
	label,
	type,
	id,
	name,
	value,
	onChange,
	placeholder,
	isRequired,
}) {
	return (
		<div className="w-full flex flex-col items-start gap-1">
			<label className="text-left text-sm text-white-1 font-semibold">
				{label} {isRequired && <span className="text-sm text-error-1">*</span>}
			</label>
			<input
				disabled
				type={type}
				id={id}
				name={name}
				placeholder={placeholder}
				className="text-[14px] placeholder:text-gray-3 text-gray-3 border-0.5 border-gray-3/20 w-full bg-transparent rounded-md py-[10px] px-[14px] focus:outline-none"
				value={value}
				onChange={onChange}
			/>
		</div>
	);
}
