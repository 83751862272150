import React from "react";

export default function DashboardWrapper({ children }) {
	return (
		<div
			className={`w-full h-full pt-[65px] pb-[45px] px-4 text-sm text-white-1`}>
			{children}
		</div>
	);
}
