import React from "react";
import { Navigate } from "react-router-dom";
import { usePlans } from "./context/PlansContext";
import useAuthStore from "./store/Auth";

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const { hasSubscriptionEnded, isSubscribed } = usePlans();

	if (!isAuthenticated) {
		return (
			<Navigate
				to="/login"
				replace
			/>
		);
	}

	if (hasSubscriptionEnded || !isSubscribed) {
		return (
			<Navigate
				to="/"
				replace
			/>
		);
	}

	return <Component {...rest} />;
};

export default ProtectedRoute;
