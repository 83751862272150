import React from "react";
import PlusIcon from "@/icons/PlusIcon";
import TickIcon from "@/icons/TickIcon";

export default function GlobalCheckbox({ id, label, isChecked, onChange }) {
	const handleCheckboxChange = () => {
		onChange(id, !isChecked); // Call the onChange function passed from the parent component
	};

	return (
		<div className="checkContainer">
			<label className="cursor-pointer">
				<input
					type="checkbox"
					name={id}
					hidden
					onChange={handleCheckboxChange}
					checked={isChecked}
				/>
				<span className="relative gap-2 flex py-[9px] px-[24px] border border-gray-3 text-gray-3 text-center text-[14px] items-center justify-center rounded-full w-full">
					{isChecked ? <TickIcon /> : <PlusIcon />}
					{label}
				</span>
			</label>
		</div>
	);
}
