import React from "react";

import {
	ErrorIcon,
	WarningIcon,
	DefaultIcon,
	SuccessIcon,
} from "../../icons/ToastIcons";

const Toast = ({ message, type, onClose }) => {
	const getToastType = () => {
		switch (type) {
			case "success":
				return <SuccessIcon />;
			case "error":
				return <ErrorIcon />;
			case "warning":
				return <WarningIcon />;
			default:
				return <DefaultIcon />;
		}
	};

	return (
		<>
			<div className="w-[350px] modalBg rounded-lg">
				<div className="text-gray-4 flex items-center gap-5 w-[100%] z-50  text-base h-fit px-6 py-6 shadow-lg rounded-2xl">
					<div className="mb-auto">
						<span className="cursor-pointer">{getToastType()}</span>
					</div>
					<div className="text-sm">{message}</div>
				</div>
			</div>
		</>
	);
};

export default Toast;
