import React from "react";
import Terms from "../Terms";
import close from "../../../src/icons/iconClose.png";
export default function DataPrivacyModal({ onClose }) {
  const dataCollectionAndTypes = [
    "Personal Data: Crafter may collect personal data such as names, email addresses, contact information, and any other information voluntarily provided by users during registration or use of the Platform.",
    "Usage Data:  Crafter may automatically collect usage data, including but not limited to IP addresses, device information, browser type, operating system, and usage patterns while users interact with the platform.",
    `Cookies and Tracking Technologies: Crafter may use cookies and other tracking technologies to enhance user experience, improve the Platform's functionality, and analyze usage trends.`,
  ];
  const purposeOfDataCollection = [
    ` Service Provision: Crafter collects and processes user data to provide and improve the functionality of the Platform, including but not limited to ticket creation, documentation management, and task analysis.`,
    ` Communication: Crafter may use user data to communicate with users regarding platform updates, announcements, and support-related inquiries`,
    `Analytics: Crafter may analyze user data to understand usage patterns, enhance user experience, and improve the quality of services offered.`,
  ];
  const dataSecurity = [
    `  Security Measures: Crafter implements industry-standard security measures to protect user data from unauthorized access, alteration, disclosure, or destruction.`,
    `  Confidentiality: Crafter ensures that user data is treated with strict confidentiality and is accessible only to authorized personnel with a legitimate need to access such data.`,
  ];
  const dataSharing = [
    `   Third-Party Service Providers: Crafter may engage third-party service providers to assist in the provision of services, subject to strict contractual obligations and compliance with applicable data protection laws.`,
    `    Legal Compliance: Crafter may disclose user data when required to do so by law, regulation, legal process, or governmental request.`,
  ];
  const userRights = [
    `   Access and Correction:  Users have the right to access, correct, or update their personal data stored by Crafter at any time.`,
    `      Withdrawal of Consent: Users may withdraw their consent for the collection, processing, or use of their data by Crafter at any time, subject to applicable legal obligations.`,
  ];
  return (
    <div className="modal">
      <div onClick={onClose} className="modal-overlay"></div>
      <div className="px-[30px] lg:px-[50px] md:px-[50px] py-5 text-gray-6 text-base flex flex-col w-[95%] lg:w-full h-[90%]  z-20 bg3 border border-gray-4/30 rounded-xl">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-2xl py-3 font-semibold text-white-1 ">
            User Data Agreement
          </h1>
          <button onClick={onClose}>
            <img src={close} alt="close" className="w-[13px] h-[13px]"/>
          </button>
        </div>
        <div className="mt-6 overflow-scroll mb-[20px] flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <p className="text-[14px] md:text-[16px] lg:text-[16px]">
              This User data agreement governs the collection, storage,
              processing, and use of user data by Crafter as part of your use of
              the platform. By accessing or using the Platform, you agree to be
              bound by the terms of this Agreement.
            </p>
          </div>

          <Terms
            title={"Data Collection and Types"}
            list={dataCollectionAndTypes}
          />
          <Terms
            title={"Purpose of Data Collection"}
            list={purposeOfDataCollection}
          />
          <Terms title={"Data Security"} list={dataSecurity} />
          <Terms title={"Data Sharing"} list={dataSharing} />
          <Terms
            title={"Data Retention"}
            message={`We will retain your information for as long as your account is active or as needed to provide you with the Service. We may also retain your information for a longer period as required by law or to comply with regulatory requirements.`}
          />
          <Terms title={"User Rights"} list={userRights} />
          <Terms
            title={"Changes to the Agreement"}
            message={`Crafter reserves the right to modify or update this Agreement at any time. Users will be notified of any material changes to the Agreement, and continued use of the Platform after such changes constitutes acceptance of the revised terms.`}
          />
        </div>
      </div>
    </div>
  );
}
