import React from "react";
import jiraGif from "@/assets/jiraIntegrationGif.gif";
import GlobalButton from "@/components/ui/GlobalButton";

export default function JiraConfigSuccess({ onClose }) {
	return (
		<div className="modal">
			<div
				onClick={onClose}
				className="modal-overlay absolute inset-0 bg-black-1/40 opacity-90"></div>

			<div className="modalBg w-[90%] md:w-[90%] lg:w-[35%] xl:max-w-[700px] max-h-[90%] px-8 py-10 h-fit top-1/2 left-1/2 absolute border-0.5 border-gray-2/50 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-5 items-center rounded-md shadow-md text-white-1">
				<div>
					<img
						src={jiraGif}
						alt="jiraGif"
						width={"100"}
					/>
				</div>
				<div className="w-full flex flex-col gap-2 items-center">
					<h1 className="font-semibold text-[20px]">Jira account integrated</h1>
					<p className="text-gray-4">
						Your Jira account has been successfully integrated on Crafter
					</p>
				</div>
				<div className="w-3/5">
					<GlobalButton
						onClick={onClose}
						variant={"blue"}
						state={"default"}
						size={"lg"}>
						Done
					</GlobalButton>
				</div>
			</div>
		</div>
	);
}
