import CrafterLogo from "@/icons/CrafterLogo";
import CrafterLogo2 from "@/icons/CrafterLogo2";
import React, { useState, useCallback } from "react";
import useAuthStore from "@/store/Auth";
import BellIcon from "@/icons/BellIcon";
import NotificationsModal from "@/components/modals/NotificationsModal";
import Polling from "./Polling";
import PendingProjects from "@/components/modals/PendingProjects";
import Check from "../icons/Check";

export default function Heading({
	isChecking,
	sentProjects,
	openPendingProjects,
	setOpenPendingProjects,
	isSubscribed,
	subscription,
	hasSubscriptionEnded,
}) {
	const { user } = useAuthStore();
	const { organization } = useAuthStore();

	const handleOpenPendingProjects = useCallback(() => {
		setOpenPendingProjects(true);
	}, [setOpenPendingProjects]);
	const handleClosePendingProjects = useCallback(() => {
		setOpenPendingProjects(false);
	}, [setOpenPendingProjects]);

	const getSubscriptionPlanName = (planName) => {
		const nameParts = planName.split(" ");
		return nameParts.length > 1 ? nameParts[1] : planName;
	};

	return (
		<header className="flex w-full items-center justify-between gap-5 px-5">
			<div>
				<CrafterLogo2 />
			</div>

			<div className="flex items-center gap-5">
				{isChecking ? (
					<div onClick={handleOpenPendingProjects}>
						<Polling />
					</div>
				) : null}

				{isSubscribed && subscription?.plan?.name ? (
					<>
						{isSubscribed &&
						!hasSubscriptionEnded &&
						subscription?.plan?.name ? (
							<div className="flex flex-row items-center text-blue-2">
								<Check />
								<h1 className="font-semibold text-sm">
									({getSubscriptionPlanName(subscription?.plan?.name)})
								</h1>
							</div>
						) : (
							<div className="flex flex-row items-center text-gray-3">
								<Check />
								<h1 className="font-semibold text-sm">
									({getSubscriptionPlanName(subscription?.plan?.name)})
								</h1>
							</div>
						)}
					</>
				) : null}

				<div className="flex flex-row items-center gap-5">
					<span className="nameSpan flex items-center justify-center font-semibold text-3xl text-white-1 w-[40px] h-[40px] rounded-full border border-gray-1">
						{user?.email?.charAt(0).toUpperCase()}
					</span>

					<div>
						<h3 className="text-gray-4 font-semibold lg:text-[16px] xl:text-[16px] 2xl:text-[18px]">
							{user?.email}
						</h3>
						<p className="text-white-1 font-semibold lg:text-[12px] xl:text-[12px] 2xl:text-[14px]">
							{organization?.name}
						</p>
					</div>
				</div>
			</div>

			{openPendingProjects && (
				<PendingProjects
					sentProjects={sentProjects}
					onClose={handleClosePendingProjects}
				/>
			)}
		</header>
	);
}
