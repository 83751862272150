import React from "react";
import GlobalButton from "@/components/ui/GlobalButton";

export default function Card1({ subscription }) {
	const subscriptionFeatures = subscription?.plan?.feature;
	// console.log(subscriptionFeatures);

	const capitalizeFirstLetter = (string) => {
		if (!string) return "";
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
	const decapitalizeFirstLetter = (string) => {
		if (!string) return "";
		return string.charAt(0).toLowerCase() + string.slice(1);
	};

	return (
		<div className="p-4 flex flex-col gap-5 h-fit w-full border-0.5 border-gray-5/50 rounded-lg notificationBg">
			<ul className="w-full text-base flex flex-col gap-5 list-disc list-outside pl-5 text-gray-4">
				<li>{`${capitalizeFirstLetter(
					subscriptionFeatures?.upload
				)} uploads + ticket generation (can upload up to 10mb document)`}</li>
				<li>{`Access to ${decapitalizeFirstLetter(
					subscriptionFeatures?.documentFormats
				)} supported document formats.`}</li>
				<li>{`${capitalizeFirstLetter(
					subscriptionFeatures?.editsSave
				)} edits + save`}</li>
				<li>{`${capitalizeFirstLetter(
					subscriptionFeatures?.csvDownloads
				)} CSV downloads`}</li>
				<li>{`${capitalizeFirstLetter(
					subscriptionFeatures?.uploadToJira
				)} exports to Jira`}</li>
			</ul>
		</div>
	);
}
