import React, { useState } from "react";

export default function InputField5({
	label,
	type,
	id,
	name,
	value,
	onChange,
	placeholder,
	isRequired,
	message,
}) {
	const [initialMessage, setInitialMessage] = useState("");
	const [hasFocused, setHasFocused] = useState(false);

	const handleFocus = () => {
		setHasFocused(true);
		setInitialMessage(message); // Show the message when the input is focused
	};

	const handleInput = () => {
		setInitialMessage("");
		setHasFocused(false);
	};

	return (
		<div className="w-full flex flex-col items-start gap-1 transition-colors duration-200">
			<label
				className={`text-left text-xs md:text-sm lg:text-sm font-semibold text-gray-4`}>
				{label} {isRequired && <span className="text-sm text-error-1">*</span>}
			</label>
			<input
				type={type}
				id={id}
				name={name}
				placeholder={placeholder}
				className={`placeholder:text-gray-3 w-full bg-transparent rounded-md py-[10px] px-[14px] border-0.5 focus:outline-none text-sm md:text-base lg:text-base ${
					message && hasFocused ? "border-orange-3" : "border-gray-3/70"
				}`}
				value={value}
				onFocus={handleFocus}
				onInput={handleInput}
				onChange={onChange}
				required={isRequired}
			/>
			<small className="text-orange-3">{initialMessage}</small>
		</div>
	);
}
