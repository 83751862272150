import React from "react";
import GlobalButton from "@/components/ui/GlobalButton";

export default function FreeCard({ freePlan, onPlanSelect }) {
	const handleNavigate = (e) => {
		e.preventDefault();
		window.open("https://usecrafter.com/join-waitlist", "_blank");
	};

	const freePlanFeatures = freePlan?.feature;

	return (
		<div className="flex flex-col gap-5 h-full px-4 py-6 w-full fullBorder6 rounded-lg leading-[25.6px]">
			<span className="flex flex-col gap-5">
				<div className="flex flex-col gap-3 w-full items-start  border-gray-3/40">
					<h1 className="text-[24px] font-medium flex flex-col gap-1 tracking-[-0.5px]">
						Free
						<span className="text-blue-1 font-bold ">$0.00</span>
					</h1>
					<p className="text-base text-white-2">
						Great for trying out Crafter app to have wind of our offers
					</p>
				</div>
				<GlobalButton
					variant={`blue`}
					state={"inactive"}
					size={"md"}>
					Current plan
				</GlobalButton>
				<ul className="w-full flex flex-col gap-4 list-disc list-outside pl-5 text-white-2 text-base">
					<li>{`${freePlanFeatures?.upload} free upload + ticket generation (document size must not be more than 10 mb),`}</li>
					<li>{`${freePlanFeatures?.editsSave} free edits + save`}</li>
					<li>{`${freePlanFeatures?.uploadToJira} free export to Jira`}</li>
					<li>{`${freePlanFeatures?.csvDownloads} CSV Download`}</li>
				</ul>
			</span>
		</div>
	);
}
