import React, { Component } from "react";
import sentGif from "@/assets/sentGif.gif";
import emailGif from "@/assets/emailGif.gif";
import GlobalButton from "@/components/ui/GlobalButton";

export default function JiraStatusModal({
	isSuccess,
	isFailed,
	onClose,
	type,
}) {
	const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);

	return (
		<div className="modal">
			<div
				onClick={(event) => {
					event.stopPropagation();
					onClose();
				}}
				className="modal-overlay absolute inset-0 bg-black-1/40 opacity-70"></div>

			<div className="modalBg w-[90%] md:w-[90%] lg:w-[35%] xl:max-w-[700px] max-h-[90%] px-8 py-10 h-fit top-1/2 left-1/2 absolute border-0.5 border-gray-2/50 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-5 items-center rounded-md shadow-md text-white-1">
				<div className=" w-full flex flex-col items-center justify-center">
					<div className="mt-[-50px]">
						<img
							src={emailGif}
							alt="sentGif"
							className="opacity-60"
							width={200}
						/>
					</div>
					<div className="mt-[20px] w-full flex flex-col items-center text-center gap-4">
						<h1 className="text-xl font-semibold">
							{capitalizedType} sent to Jira
						</h1>
						<div className="mt-3 w-full">
							<GlobalButton
								onClick={onClose}
								variant={"blue"}
								state={"default"}
								size={"lg"}>
								Done
							</GlobalButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
