import React from "react";

const TextArea2 = ({
	label,
	id,
	name,
	value,
	onChange,
	placeholder,
	isRequired,
	maxLength,
}) => {
	// const maxLength = 100; // Character limit

	const handleChange = (event) => {
		// Check if the input value exceeds the character limit
		if (event.target.value.length <= maxLength) {
			onChange(event.target.value);
		}
	};

	return (
		<div className="relative w-full flex flex-col items-start gap-2">
			<label className="text-sm text-gray-4 font-semibold">
				{label} {isRequired && <span className="text-sm text-error-1">*</span>}
			</label>
			<textarea
				id={id}
				name={name}
				placeholder={placeholder}
				className="placeholder:text-gray-2 text-gray-4 w-full h-[200px] bg-transparent rounded-md py-[10px] px-[14px] border-1 border-black-1 focus:outline-none"
				value={value}
				onChange={handleChange}
				maxLength={maxLength} // Set the maximum length
			/>
			<p className="absolute bottom-3 right-5 text-xs text-gray-4">
				{value.length}/{maxLength}
			</p>
		</div>
	);
};

export default TextArea2;
