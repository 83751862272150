import React from "react";

import { Link, NavLink } from "react-router-dom";

export default function MenuItem({
	icon: Icon,
	title,
	link,
	target,
	collapsed,
	active,
}) {
	return (
		<NavLink
			to={link}
			target={target}
			className={`px-3 py-2 flex items-center gap-2 rounded-sm hover:bg-black-1 ${
				active ? "bg-black-1 font-semibold text-white-1" : ""
			}`}>
			<div className="w-[50px] py-2 flex items-center justify-center">
				<Icon active={active} /> {/* Pass active prop to the icon component */}
			</div>
			{!collapsed && <p>{title}</p>}
		</NavLink>
	);
}
