import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Body from "./Body";
import KycForm from "@/components/modals/kycForm";
import useAuthStore from "@/store/Auth";
import PlanExpiry from "../../components/modals/PlanExpiry";
import GetOnboard from "../../components/modals/GetOnboard";
import LoadingComponent from "../../components/LoadingComponent";
import { usePlans } from "../../context/PlansContext";
import { useProjects } from "../../context/ProjectsContext";

export default function Homepage({
	onCreateTickets,
	sentProjects,
	setSentProjects,
	projectsTotal,
	setProjectsTotal,
	setCreateTicketsResponse,
}) {
	const [showKYCForm, setShowKYCForm] = useState(false);
	const [isKYCCompleted, setIsKYCCompleted] = useState(false);
	const [showPlanExpiry, setShowPlanExpiry] = useState(false);
	const [showGetOnboard, setShowGetOnboard] = useState(false);
	const [loading, setLoading] = useState(true);

	const authStore = useAuthStore();
	const { hasSubscriptionEnded, isSubscribed } = usePlans();
	const { projects, loading: projectsLoading, refetchProjects } = useProjects();

	const isNew = authStore?.user?.isNew;
	const isAuthenticated = authStore?.isAuthenticated;

	useEffect(() => {
		if (isAuthenticated) {
			if (isNew) {
				setShowKYCForm(true);
			} else {
				if (!isSubscribed) {
					setShowGetOnboard(true);
				} else if (hasSubscriptionEnded) {
					setShowPlanExpiry(true);
				} else {
					setShowGetOnboard(false);
				}
			}
		}
		setLoading(false);
	}, [isAuthenticated, isNew, isSubscribed, hasSubscriptionEnded]);

	const closeKYCForm = () => {
		setShowKYCForm(false);
		setIsKYCCompleted(true);
		if (!isSubscribed) {
			setShowGetOnboard(true);
		}
	};

	const closePlanExpiry = () => {
		setShowPlanExpiry(false);
	};

	const closeGetOnboard = () => {
		setShowGetOnboard(false);
	};

	if (loading) {
		return (
			<div>
				<LoadingComponent />
			</div>
		);
	}

	return (
		<main className="relative bg-black-4 text-gray-4 h-screen w-full flex flex-col">
			<Helmet>
				<title>Dashboard | Crafter</title>
			</Helmet>
			<div className="flex w-full h-full bg-black-6 ">
				<div className={`w-full overflow-hidden h-full `}>
					<Body
						onCreateTickets={onCreateTickets}
						projectsTotal={projectsTotal}
						setProjectsTotal={setProjectsTotal}
						sentProjects={sentProjects}
						setSentProjects={setSentProjects}
						setCreateTicketsResponse={setCreateTicketsResponse}
						projects={projects}
						loading={loading}
						refetchProjects={refetchProjects}
					/>
				</div>
			</div>
			{showKYCForm && <KycForm onClose={closeKYCForm} />}
			{showPlanExpiry && <PlanExpiry onClose={closePlanExpiry} />}
			{showGetOnboard && <GetOnboard onClose={closeGetOnboard} />}
		</main>
	);
}
