import React, { useState, useEffect } from "react";
import GlobalButton from "@/components/ui/GlobalButton";
import GlobalDropdown2 from "@/components/ui/GlobalDropdown2";
import TextArea2 from "@/components/ui/TextArea2";
import TextArea from "@/components/ui/TextArea";
import ReactGA from "react-ga4";
import useAuthStore from "@/store/Auth";
import ErrorToast from "@/components/modals/ErrorToast";
import SuccessToast from "@/components/modals/SuccessToast";
import WebAppService from "../../services/WebAppService";
import { useToast } from "../../context/ToastContext";

export default function KycForm({ onClose }) {
	const [step, setStep] = useState(0);
	const [formData, setFormData] = useState({
		role: "",
		message: "",
		employees: "",
	});
	const [nextButtonState, setNextButtonState] = useState("inactive");
	const [submitButtonState, setSubmitButtonState] = useState("inactive");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [errorToast, setErrorToast] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [successToast, setSuccessToast] = useState(false);
	const authStore = useAuthStore();
	const { addToast } = useToast();

	const userId = authStore.user.id;

	// Function to update form data for each step
	const updateFormData = (name, value) => {
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	// Function to handle dropdown change
	const handleDropdownChange = (name, value) => {
		updateFormData(name, value);
		setNextButtonState(value ? "default" : "inactive");
	};

	// Function to handle text area change
	const handleInputChange = (name, event) => {
		const value = event.target.value; // Extract value from event
		updateFormData(name, value);
	};

	useEffect(() => {
		setSubmitButtonState(
			formData.message && formData.message.length <= 100
				? "default"
				: "inactive"
		);
	}, [formData.message]);

	const steps = [
		{
			component: GlobalDropdown2,
			props: {
				label: "What is your role in your company?",
				placeholder: "Select from the options below",
				name: "role",
				options: [
					"Cybersecurity Analyst",
					"Data Scientist",
					"Database Administrator (DBA)",
					"Engineering",
					"IT Auditor",
					"IT Support Specialist",
					"IT Consultant",
					"Product Manager",
					"Systems Administrator",
					"Technical Writer",
					"UX/UI Designer",
				],
				onChange: handleDropdownChange,
				value: formData.role || "",
			},
		},
		{
			component: GlobalDropdown2,
			props: {
				label: "How many people work here?",
				placeholder: "Select from the options below",
				name: "employees",
				options: [
					"1-10 employees",
					"20-50 employees",
					"50-100 employees",
					"100+ employees",
				],
				onChange: handleDropdownChange,
				value: formData.employees || "",
			},
		},
		{
			component: TextArea,
			props: {
				label: "How do you plan to use Crafter?",
				name: "message",
				placeholder: "Type your reason here",
				value: formData.message || "",
				onChange: handleInputChange,
				maxLength: 100,
			},
		},
	];

	const totalSteps = steps.length;

	const nextStep = () => {
		setStep((prevStep) => prevStep + 1);
		setNextButtonState("inactive");
	};

	const prevStep = () => {
		setStep((prevStep) => prevStep - 1);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSubmitting(true);

		const { role, employees, message } = formData;

		ReactGA.event({
			category: "kyc",
			action: "kyc_submit",
			label: JSON.stringify({
				uid: userId,
				company_role: role || "skipped",
				employee_count: employees || "skipped",
				use_reason: message || "skipped",
			}),
		});

		try {
			const updateFlagResponse = await WebAppService.updateFlag(userId);

			if (updateFlagResponse?.success) {
				authStore.updateUser({
					email: updateFlagResponse?.data?.email,
					role: updateFlagResponse?.data?.role,
					createdAt: updateFlagResponse?.data?.created_at,
					updatedAt: updateFlagResponse?.data?.updated_at,
					id: updateFlagResponse?.data?.id,
					organization: updateFlagResponse?.data?.organization,
					isNew: updateFlagResponse?.data?.isNew,
				});

				addToast(
					"Form submitted. Thank you for your feedback",
					"success",
					3000
				);
				setTimeout(() => {
					// setSuccessToast(true);
					onClose();
				}, 2000);
			}
		} catch (error) {
			setIsSubmitting(false);

			addToast(
				`${error?.response?.data?.error || "Something went wrong"}`,
				"error",
				3000
			);

			console.error(error);
		}
	};

	const handleSubmitSkip = async (e) => {
		e.preventDefault();

		setIsSubmitting(true);

		const { role, employees, message } = formData;

		ReactGA.event({
			category: "kyc",
			action: "kyc_submit",
			label: JSON.stringify({
				uid: userId,
				company_role: role || "skipped",
				employee_count: employees || "skipped",
				use_reason: message || "skipped",
			}),
		});

		try {
			const updateFlagResponse = await WebAppService.updateFlag(userId);

			if (updateFlagResponse?.success) {
				authStore.updateUser({
					email: updateFlagResponse?.data?.email,
					role: updateFlagResponse?.data?.role,
					createdAt: updateFlagResponse?.data?.created_at,
					updatedAt: updateFlagResponse?.data?.updated_at,
					id: updateFlagResponse?.data?.id,
					organization: updateFlagResponse?.data?.organization,
					isNew: updateFlagResponse?.data?.isNew,
				});

				setTimeout(() => {
					// setSuccessToast(true);
					onClose();
				}, 2000);
			}
		} catch (error) {
			setIsSubmitting(false);

			console.error(error);
		}
	};

	const handleSkip = async (e) => {
		e.preventDefault();

		updateFormData(steps[step].props.name, "skipped");
		// console.log(steps[step].props.name, "skipped");

		if (step === totalSteps - 1) {
			ReactGA.event({
				category: "kyc",
				action: "kyc_submit",
				label: JSON.stringify({
					uid: userId,
					step: step + 1,
				}),
			});

			// console.log(formData);
			onClose();
			handleSubmitSkip(e);
		} else {
			nextStep();
		}
	};

	const handleDismiss = async (e) => {
		e.preventDefault();

		// Update all form fields to "skipped"
		const skippedFormData = {};
		Object.keys(formData).forEach((key) => {
			skippedFormData[key] = "skipped";
		});

		onClose();

		ReactGA.event({
			category: "kyc",
			action: "kyc_submit",
			label: JSON.stringify({
				uid: userId,
				company_role: skippedFormData.role,
				employee_count: skippedFormData.employees,
				use_reason: skippedFormData.message,
				step: totalSteps, // Assuming this is the last step when dismissing
			}),
		});

		try {
			const updateFlagResponse = await WebAppService.updateFlag(userId);
			if (updateFlagResponse?.success) {
				authStore.updateUser({
					email: updateFlagResponse?.data?.email,
					role: updateFlagResponse?.data?.role,
					createdAt: updateFlagResponse?.data?.created_at,
					updatedAt: updateFlagResponse?.data?.updated_at,
					id: updateFlagResponse?.data?.id,
					organization: updateFlagResponse?.data?.organization,
					isNew: updateFlagResponse?.data?.isNew,
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	// Render the current step
	const renderStep = () => {
		const StepComponent = steps[step].component;
		const stepProps = {
			...steps[step].props,
			value: formData[steps[step].props.name] || "",
			onChange: (event) => {
				steps[step].props.onChange(steps[step].props.name, event); // Pass the event object
			},
		};

		return (
			<div className="modal">
				<div
					onClick={handleDismiss}
					className="absolute inset-0 bg-black-6 opacity-95"></div>
				<div className="relative flex m-auto bg-black-6 border-0.5 border-blue-2/40 h-fit py-10 px-16 md:py-10 lg:py-24 w-[90%] lg:w-[70%] xl:max-w-[800px] rounded-lg">
					<div className="flex flex-col gap-7 text-left lg:text-center w-full lg:mx-auto">
						<div className="flex flex-col gap-2">
							<h1 className="text-white-1 font-bold text-[20px] lg:text-[24px] tracking-[-0.4px]">
								Hello, Welcome to Crafter
							</h1>
							<p className="text-[12px] md:text-[14px] lg:text-[16px]">
								To serve you better, we will like to know a little more about
								what you do{" "}
							</p>
						</div>
						<form
							className=" flex flex-col gap-6 w-full text-white-1 mt-4"
							onSubmit={handleSubmit}>
							<div className="relative flex flex-col gap-6">
								<StepComponent {...stepProps} />
								{step === totalSteps - 1 && (
									<div
										className={`absolute bottom-3 right-5 text-sm ${
											formData.message.length > 100
												? "text-error-2"
												: "text-white-3"
										} mt-2`}>
										{formData.message.length}/{steps[step].props.maxLength}
									</div>
								)}
							</div>
							<div className="mt-2 flex flex-col gap-3">
								{step === totalSteps - 1 ? (
									<GlobalButton
										onClick={handleSubmit}
										variant="secondary"
										size="md"
										state={isSubmitting ? "inactive" : submitButtonState}
										type="submit">
										{isSubmitting ? "Submitting" : "Submit"}
									</GlobalButton>
								) : (
									<GlobalButton
										onClick={nextStep}
										variant="secondary"
										size="md"
										state={nextButtonState}
										type="button">
										Next
									</GlobalButton>
								)}
								<GlobalButton
									size={`md`}
									onClick={handleSkip}>
									Skip
								</GlobalButton>
							</div>
						</form>
					</div>
					<p className="text-xl text-gray-2 absolute bottom-10 right-10">
						<span className="text-white-1">{step + 1}</span> of {totalSteps}
					</p>
				</div>
				{errorToast && <ErrorToast message={errorMessage} />}
				{successToast && (
					<SuccessToast
						message={`Form submitted. Thank you for your feedback`}
					/>
				)}
			</div>
		);
	};

	return <div>{renderStep()}</div>;
}
