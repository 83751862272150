import React from "react";

export default function DocTextIcon() {
	return (
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.0835 8.93047H14.6752C12.7002 8.93047 11.0918 7.32214 11.0918 5.34714V2.9388C11.0918 2.48047 10.7168 2.10547 10.2585 2.10547H6.72516C4.1585 2.10547 2.0835 3.77214 2.0835 6.74714V14.1305C2.0835 17.1055 4.1585 18.7721 6.72516 18.7721H13.2752C15.8418 18.7721 17.9168 17.1055 17.9168 14.1305V9.7638C17.9168 9.30547 17.5418 8.93047 17.0835 8.93047Z"
				fill="#333333"
			/>
			<path
				d="M13.1662 2.28054C12.8246 1.93888 12.2329 2.17221 12.2329 2.64721V5.55554C12.2329 6.77221 13.2662 7.78054 14.5246 7.78054C15.3162 7.78888 16.4162 7.78888 17.3579 7.78888C17.8329 7.78888 18.0829 7.23054 17.7496 6.89721C16.5496 5.68888 14.3996 3.51388 13.1662 2.28054Z"
				fill="#0182AB"
			/>
			<path
				d="M11.25 11.8975H6.25C5.90833 11.8975 5.625 11.6141 5.625 11.2725C5.625 10.9308 5.90833 10.6475 6.25 10.6475H11.25C11.5917 10.6475 11.875 10.9308 11.875 11.2725C11.875 11.6141 11.5917 11.8975 11.25 11.8975Z"
				fill="#F0EEEE"
			/>
			<path
				d="M9.58333 15.2305H6.25C5.90833 15.2305 5.625 14.9471 5.625 14.6055C5.625 14.2638 5.90833 13.9805 6.25 13.9805H9.58333C9.925 13.9805 10.2083 14.2638 10.2083 14.6055C10.2083 14.9471 9.925 15.2305 9.58333 15.2305Z"
				fill="#F58E30"
			/>
		</svg>
	);
}
