import React from "react";

export default function BackIcon({ onClick, className }) {
	return (
		<svg
			className={`cursor-pointer ${className}`}
			onClick={onClick}
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.542 16.6004L7.10866 11.1671C6.46699 10.5254 6.46699 9.47539 7.10866 8.83372L12.542 3.40039"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
