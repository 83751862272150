import React, { useState } from "react";
import Card from "./Card";
import JiraIcon from "@/icons/JiraIcon";
import SlackIcon from "@/icons/SlackIcon";
import GithubIcon from "@/icons/GithubIcon";
import DashboardContainer from "@/components/ui/DashboardContainer";
import DashboardWrapper from "@/components/ui/DashboardWrapper";
import useAuthStore from "@/store/Auth";
import { useNavigate } from "react-router-dom";

export default function Body({ collapsed }) {
	const authStore = useAuthStore();
	const navigate = useNavigate();

	const handleIntegrationClick = (integration) => {
		navigate(`/integrations/${integration}`);
	};

	return (
		<div>
			<DashboardWrapper>
				<DashboardContainer>
					<div className="flex items-center justify-between">
						<div className="flex items-center justify-between">
							<h3 className="font-semibold text-[20px]">Integrations</h3>
						</div>
					</div>
					<div className="flex flex-col gap-y-5">
						<div className="flex flex-col gap-y-6">
							<p className="text-[16px] text-gray-4">
								Integrate crafter with your favorite productivity tools to
								better manage your projects/teams
							</p>
							<div className="flex gap-4 lg:h-[220px] xl:h-[200px]">
								<Card
									collapsed={collapsed}
									onClick={() => handleIntegrationClick("Jira")}
									component={<JiraIcon />}
									title={"Jira"}
									description={
										"A project management tool developed by Atlassian for software development teams."
									}
									isInactive={false}
								/>
								<Card
									component={<SlackIcon />}
									title={"Slack"}
									description={
										"A platform designed to improve team communication and collaboration."
									}
									available={false}
									isInactive={true}
								/>
								<Card
									component={<GithubIcon />}
									title={"Github"}
									description={
										"A version control system, which allows developers to track changes in code over time."
									}
									available={false}
									isInactive={true}
								/>
							</div>
						</div>
					</div>
				</DashboardContainer>
			</DashboardWrapper>
		</div>
	);
}
