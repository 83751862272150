import React, { useEffect, useState } from "react";
import InputField from "@/components/ui/InputField";
import InputField4 from "@/components/ui/InputField4";
import JiraIcon from "@/icons/JiraIcon";
import GlobalButton from "@/components/ui/GlobalButton";
import useAuthStore from "@/store/Auth";
import WebAppService from "@/services/WebAppService";
import JiraConfigSuccess from "@/components/modals/JiraConfigSuccess";
import JiraConfigFailed from "@/components/modals/JiraConfigFailed";
import { useNavigate } from "react-router-dom";

const JiraIntegrationForm = ({ onCancel }) => {
	const authStore = useAuthStore();
	const navigate = useNavigate();

	const organizationName = authStore.organization.name;

	const [formData, setFormData] = useState({
		name: organizationName,
		jira_admin_email: "",
		jira_url: "",
		jira_api_key: "",
	});
	const [formValid, setFormValid] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isFailed, setIsFailed] = useState(false);
	const [formErrors, setFormErrors] = useState(false);

	const closeJiraConfigSuccessModal = () => {
		setIsSuccess(false);
	};
	const closeJiraConfigFailedModal = () => {
		setIsFailed(false);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		// Validate input based on its name
		let error = "";
		switch (name) {
			case "jira_admin_email":
				error =
					value.trim() === ""
						? "Email is required"
						: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
						? ""
						: "Invalid email format";
				break;
			case "jira_url":
				error =
					value.trim() === ""
						? "Jira URL is required"
						: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(
								value
						  ) && value.endsWith(".atlassian.net")
						? ""
						: "Invalid Jira URL format";
				break;
			case "jira_api_key":
				error =
					value.trim() === ""
						? "Jira API key is required"
						: value.length > 10
						? ""
						: "Jira API key must be at least 10 characters long";
				break;
			default:
				break;
		}

		// Update form data and errors
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		// Update error state for the current field
		setFormErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const organizationId = authStore.organization.id;
		setIsSubmitting(true);

		try {
			const configureJiraResponse = await WebAppService.configureJira(
				organizationId,
				formData
			);

			if (configureJiraResponse.success) {
				setFormData({
					jira_admin_email: "",
					jira_url: "",
					jira_api_key: "",
				});

				setIsFailed(false);
				setIsSuccess(true);

				setTimeout(() => {
					setIsSuccess(false);
					setIsFailed(false);
					navigate("/integrations");
				}, 5000);
			}

			const configureJiraResponseData = configureJiraResponse.data;

			authStore.updateOrganization({
				hasSetJiraConfig: configureJiraResponseData.has_set_jira_api_key,
				domain: configureJiraResponseData.domain,
				name: configureJiraResponseData.name,
				updatedAt: configureJiraResponseData.updatedAt,
				jira_url: configureJiraResponseData.jira_url,
				jira_admin_email: configureJiraResponseData.jira_admin_email,
				id: configureJiraResponseData.id,
			});
		} catch (error) {
			setIsFailed(true);
			setIsSuccess(false);

			setTimeout(() => {
				setIsFailed(false);
				setIsSuccess(false);
			}, 5000);
			throw error;
		}

		setIsSubmitting(false);
	};

	const handleCancel = () => {
		navigate("/integrations");
	};

	useEffect(() => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isEmailValid = emailRegex.test(formData.jira_admin_email);

		const urlRegex =
			/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
		const isUrlValid =
			formData.jira_url !== "" &&
			urlRegex.test(formData.jira_url) &&
			formData.jira_url.endsWith(`.atlassian.net`);

		const isApiKeyValid =
			formData.jira_api_key !== "" && formData.jira_api_key.length > 10;

		setFormValid(isEmailValid && isUrlValid && isApiKeyValid);
	}, [formData]);

	return (
		<div className="w-full h-screen pt-[60px] pb-[45px] px-4 text-sm text-white-1">
			<div className="flex innerFrameBg flex-col gap-8 w-full h-full p-20 mt-8 border border-gray-2/30 rounded-md">
				<div className="flex flex-col gap-10 w-full  lg:w-[65%] 2xl:max-w-[50%] h-full p-8 mx-auto  rounded-md">
					<div className="flex flex-col gap-2">
						<div className="flex items-start gap-4">
							<JiraIcon />
							<h1 className="text-[20px] font-semibold">Integrate Jira</h1>
						</div>
						<p className="text-gray-6">
							Connect your Jira to Crafter. This will override any previous Jira
							configuration. Integrating with Jira allows Crafter to land
							tickets directly into your Jira project.
						</p>
					</div>
					<form
						className="w-full"
						onSubmit={handleSubmit}>
						<div className="flex flex-col gap-8">
							<InputField4
								type={`email`}
								label={`Organization's Jira Admin Email`}
								name="jira_admin_email"
								value={formData.jira_admin_email}
								onChange={handleChange}
								isRequired={true}
								placeholder={`Input your organization's Jira admin email`}
							/>
							{formErrors.jira_admin_email && (
								<span className="text-error-2 mt-[-25px] italic text-[12px]">
									{formErrors.jira_admin_email}
								</span>
							)}

							<InputField4
								type={`url`}
								label={`Organization’s Jira URL`}
								name="jira_url"
								value={formData.jira_url}
								onChange={handleChange}
								isRequired={true}
								placeholder={`https://example.atlassian.net`}
							/>
							{formErrors.jira_url && (
								<span className="text-error-2 mt-[-25px] italic text-[12px]">
									{formErrors.jira_url}
								</span>
							)}

							<InputField4
								type={`password`}
								label={`Organization’s Jira API key`}
								name="jira_api_key"
								value={formData.jira_api_key}
								onChange={handleChange}
								isRequired={true}
								placeholder={`Input your organization’s Jira API key`}
							/>
							{formErrors.jira_api_key && (
								<span className="text-error-2 mt-[-25px] italic text-[12px]">
									{formErrors.jira_api_key}
								</span>
							)}
						</div>

						<div className="flex w-full justify-end mt-10">
							<div className="flex gap-2">
								<div className="min-w-32">
									<GlobalButton
										variant={"blue"}
										size={"sm"}
										state={isSubmitting || !formValid ? "inactive" : "default"}
										type="submit"
										disabled={!formValid || isSubmitting}>
										{isSubmitting ? "Syncing..." : "Sync Jira"}
									</GlobalButton>
								</div>
								<div className="min-w-32">
									<GlobalButton
										onClick={handleCancel}
										variant={"secondary"}
										size={"sm"}
										state={"default"}>
										Cancel
									</GlobalButton>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>

			{isSuccess && <JiraConfigSuccess onClose={closeJiraConfigSuccessModal} />}

			{isFailed && <JiraConfigFailed onClose={closeJiraConfigFailedModal} />}
		</div>
	);
};

export default JiraIntegrationForm;
