import React from "react";
import clsx from "clsx";

export default function Card({
	component,
	title,
	description,
	isInactive,
	available,
	onClick,
	collapsed,
}) {
	const cardClass = clsx({
		"opacity-50 blur-none cursor-not-allowed": isInactive,
		"cursor-pointer": isInactive === false,
	});

	return (
		<div
			onClick={onClick}
			className={`relative w-full md:w-full lg:w-[35%] text-white-3 sidebarBg hover:bg-black-1 h-auto  px-3 md:px-4 lg:px-6 py-3 md:py-6 lg:py-10 flex gap-4 lg:gap-6 items-start border border-gray-2/30 rounded-md ${cardClass}`}>
			{available === false && (
				<div className="absolute top-5 right-5 text-white px-2 py-1 rounded-tr-md text-xs font-semibold text-error-2">
					Coming soon
				</div>
			)}
			<div>{component}</div>
			<div className="flex flex-col gap-3">
				<h2 className="font-semibold text-[20px] md:text-[20px] lg:text-[20px] xl:text-[24px]">
					{title}
				</h2>
				<p className={`text-[12px] md:text-[16px] lg:text-[14px] text-gray-4`}>
					{description}
				</p>

				<div
					className={`${available === false ? "cursor-not-allowed" : ""}
							 flex absolute bottom-5 right-3`}>
					<button
						onClick={onClick}
						className={`${
							available === false ? "cursor-not-allowed" : ""
						} text-blue-2 border border-blue-2 rounded-full px-4 py-2 text-sm font-semibold`}>
						Integrate {title}
					</button>
				</div>
			</div>
		</div>
	);
}
