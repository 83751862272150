import SearchField from "@/components/ui/SearchField";
import React, { useState, useEffect } from "react";
import WebAppService from "@/services/WebAppService";
import DashboardWrapper from "@/components/ui/DashboardWrapper";
import Project3 from "./Project3";
import { Helmet } from "react-helmet";
import Empty from "@/components/ui/Empty";
import LoadingComponent from "@/components/LoadingComponent";

export default function Body() {
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(() => {
		const storedPage = localStorage.getItem("currentPage");
		return storedPage && !isNaN(parseInt(storedPage, 10))
			? parseInt(storedPage, 10)
			: 1;
	});
	const [itemsPerPage, setItemsPerPage] = useState(8); // You can set this default value or get it from BE
	const [totalPages, setTotalPages] = useState(1);
	const [nextPage, setNextPage] = useState(false);
	const [previousPage, setPreviousPage] = useState(false);

	useEffect(() => {
		const storedPage = localStorage.getItem("currentPage");
		if (storedPage && !isNaN(parseInt(storedPage, 10))) {
			setCurrentPage(parseInt(storedPage, 10));
		} else {
			// If no stored value or invalid value in localStorage, set default value
			setCurrentPage(1);
		}
	}, []);

	useEffect(() => {
		// Save currentPage to localStorage whenever it changes
		localStorage.setItem("currentPage", currentPage.toString());
	}, [currentPage]);

	useEffect(() => {
		const fetchProjects = async () => {
			setLoading(true);
			try {
				const response = await WebAppService.getAllTasks(currentPage);
				setProjects(response.data);
				setTotalPages(response.pagination?.total_pages);
				setNextPage(response.pagination?.has_next_page);
				setPreviousPage(response.pagination?.has_previous_page);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching organisation data:", error);
				setLoading(false);
			}
		};

		fetchProjects();
	}, [currentPage]);

	const handlePageChange = (page) => {
		if (page > currentPage) {
			// If the selected page is greater than the current page, fetch the next page
			handleNextPage(page);
		} else if (page < currentPage) {
			// If the selected page is less than the current page, fetch the previous page
			handlePrevPage(page);
		}
	};

	const handleNextPage = (page) => {
		if (nextPage) {
			WebAppService.getAllProjects(page)
				.then((response) => {
					setProjects(response.data);
					setCurrentPage(page);
					// console.log(response);
				})

				.catch((error) => {
					console.error("Error fetching organisation data:", error);
					setLoading(false);
				});
		}
	};

	const handlePrevPage = (page) => {
		if (currentPage > 1) {
			WebAppService.getAllProjects(page)
				.then((response) => {
					setProjects(response.data);
					setCurrentPage(page);
					// console.log(response);
				})
				.catch((error) => {
					console.error("Error fetching organisation data:", error);
					setLoading(false);
				});
		}
	};

	const filteredProjects = searchQuery
		? projects.filter(
				(project) =>
					(project &&
						project.projectLabel &&
						project.name &&
						typeof project.projectLabel === "string" &&
						typeof project.name === "string" &&
						project.projectLabel
							.toLowerCase()
							.includes(searchQuery.toLowerCase())) ||
					project.name.toLowerCase().includes(searchQuery.toLowerCase())
		  )
		: projects;

	return (
		<DashboardWrapper>
			<Helmet>
				<title>Projects | Crafter</title>
			</Helmet>
			<div className="flex bg3 flex-col gap-4 w-full h-full px-8 pt-8 pb-3 mt-8 border border-gray-2/30 rounded-md overflow-hidden innerFrameBg">
				<div className="flex items-center justify-between">
					<h3 className="font-semibold text-[20px]">Projects</h3>
					<div className="flex items-center gap-3 w-[50%]">
						<SearchField
							placeholder={"Search project"}
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</div>
				</div>

				<div className="flex flex-col gap-y-5 overflow-scroll mb-[-20px]">
					<div className=" h-full">
						{loading ? (
							<div className="flex m-auto min-h-[450px]">
								<LoadingComponent />
							</div>
						) : projects.length === 0 ? (
							<div className="flex justify-center items-center min-h-[450px]">
								<Empty
									message={`You currently have no projects`}
									text={`Create a new project to analyze your product specs and tech documents`}
								/>
							</div>
						) : filteredProjects.length === 0 ? (
							<div className="flex justify-center items-center min-h-[450px]">
								<Empty
									message={`No matching projects found`}
									text={`Try another search term`}
								/>
							</div>
						) : (
							<div className="flex h-full flex-col gap-y-4">
								{filteredProjects.map((project, index) => {
									return (
										<div key={index}>
											<Project3 project={project} />
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
				<div className="flex flex-end mt-auto justify-center items-center rounded-full  px-10 py-3 pagination ">
					<div className="flex gap-1">
						<button
							className={`text-white-1 paginationBtn px-5 py-2 rounded-lg font-semibold ${
								currentPage === 1 ? "opacity-20" : ""
							}`}
							onClick={() => handlePrevPage(currentPage - 1)}>
							Prev
						</button>
						{/* Display page numbers */}
						{[...Array(totalPages).keys()].map((page) => {
							// Adjusting to 1-based index for display
							const displayPage = page + 1;

							// Display only the current page number, the previous page, and the next page
							if (
								displayPage === currentPage ||
								displayPage === currentPage - 1 ||
								displayPage === currentPage + 1
							) {
								return (
									<button
										key={page}
										className={`px-4 py-2 rounded-sm font-semibold ${
											displayPage === currentPage ? "pageBtnBg" : "opacity-20"
										}`}
										onClick={() => handlePageChange(displayPage)}>
										{displayPage}
									</button>
								);
							}

							// Display ellipsis for other pages
							if (
								displayPage !== currentPage - 1 &&
								displayPage !== currentPage &&
								displayPage !== currentPage + 1 &&
								displayPage !== 1 &&
								displayPage !== totalPages
							) {
								// return <span key={page}>...</span>;
							}

							return null;
						})}
						<button
							className={`text-white-1 paginationBtn px-5 py-2 rounded-lg font-semibold ${
								currentPage === totalPages && projects.length === 0
									? ""
									: "opacity-20"
							}`}
							onClick={() => handleNextPage(currentPage + 1)}>
							Next
						</button>
					</div>
				</div>
			</div>
		</DashboardWrapper>
	);
}
