import React, { useState } from "react";
import GlobalButton from "@/components/ui/GlobalButton";

export default function LiteCard({
	onContinue,
	litePlan,
	activePeriod,
	onPlanSelect,
}) {
	const [buttonState, setButtonState] = useState("default");

	const litePlanFeatures = litePlan?.feature;

	const capitalizeFirstLetter = (string) => {
		if (!string) return "";
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
	const decapitalizeFirstLetter = (string) => {
		if (!string) return "";
		return string.charAt(0).toLowerCase() + string.slice(1);
	};

	// Define pricing based on activePeriod
	const pricing =
		activePeriod === "Monthly"
			? `$${litePlan?.prices?.monthly[0]?.amount}`
			: `$${litePlan?.prices?.yearly[0]?.amount}`;

	const handleButtonClick = () => {
		setButtonState("inactive");
		onPlanSelect(litePlan?.id);
	};

	return (
		<div className="flex flex-col gap-5 h-fit px-4 py-6 w-full fullBorder5 rounded-lg leading-[25.6px]">
			<div className="flex flex-col gap-3 w-full items-start">
				<h1 className="text-[24px] font-medium flex flex-col gap-1 tracking-[-0.5px]">
					{litePlan?.name || `Crafter Lite`}
					<span className="text-blue-1 font-bold ">{pricing}</span>
				</h1>
				<p className="text-base text-white-2">
					Work faster and better by creating tickets easily
				</p>
			</div>
			<div className="flex flex-col gap-8">
				<div>
					<GlobalButton
						onClick={handleButtonClick}
						variant={"secondary"}
						size={"md"}
						state={buttonState}>
						Subscribe
					</GlobalButton>
				</div>

				<ul className="w-full text-base flex flex-col gap-4 list-disc list-outside pl-5 text-white-2">
					<li>{`${capitalizeFirstLetter(
						litePlanFeatures?.upload
					)} uploads + ticket generation (can upload up to 10mb document)`}</li>
					<li>{`Access to ${decapitalizeFirstLetter(
						litePlanFeatures?.documentFormats
					)} supported document formats.`}</li>
					<li>{`${capitalizeFirstLetter(
						litePlanFeatures?.editsSave
					)} edits + save`}</li>
					<li>{`${capitalizeFirstLetter(
						litePlanFeatures?.csvDownloads
					)} CSV downloads`}</li>
					<li>{`${capitalizeFirstLetter(
						litePlanFeatures?.uploadToJira
					)} exports to Jira`}</li>
				</ul>
			</div>
		</div>
	);
}
