// import React, { useState, useEffect } from "react";
// import Plan from "./Plan";
// import Billing from "./Billing";
// import LoadingComponent from "@/components/LoadingComponent";
// import { usePlans } from "../../context/PlansContext";

// export default function Body({ handleCheckout }) {

// 	return (

// 	);
// }
