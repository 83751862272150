import React, { useState } from "react";
import Body from "./Body";

export default function Projects() {
	const [collapsed, setCollapsed] = useState(false);

	return (
		<main className="relative bg-black-6 text-gray-4 h-screen overflow-hidden w-full flex flex-col">
			<div className="flex w-full h-full">
				<div
					className={`w-full ${
						collapsed ? "w-[95%]" : "w-[80%]"
					} overflow-scroll h-full`}>
					<Body />
				</div>
			</div>
		</main>
	);
}
