import React from "react";

export default function EyeIcon() {
	return (
		<svg
			width="15"
			height="15"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.9833 9.99993C12.9833 11.6499 11.6499 12.9833 9.99993 12.9833C8.34993 12.9833 7.0166 11.6499 7.0166 9.99993C7.0166 8.34993 8.34993 7.0166 9.99993 7.0166C11.6499 7.0166 12.9833 8.34993 12.9833 9.99993Z"
				stroke="currentColor"
				stroke-width="1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.0001 16.8913C12.9418 16.8913 15.6834 15.1579 17.5918 12.1579C18.3418 10.9829 18.3418 9.00794 17.5918 7.83294C15.6834 4.83294 12.9418 3.09961 10.0001 3.09961C7.05845 3.09961 4.31678 4.83294 2.40845 7.83294C1.65845 9.00794 1.65845 10.9829 2.40845 12.1579C4.31678 15.1579 7.05845 16.8913 10.0001 16.8913Z"
				stroke="currentColor"
				stroke-width="1"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
