import React from "react";
import loadingGif from "@/assets/loadingGif.gif";

export default function LoadingComponent() {
	return (
		<img
			src={loadingGif}
			alt="loading"
			width={"100"}
			className="m-auto flex"
		/>
	);
}
