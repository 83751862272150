import React, { useState, useEffect } from "react";
import signupsvg from "@/assets/signupsvg.svg";
import InputField from "@/components/ui/InputField";
import GlobalButton from "@/components/ui/GlobalButton";
import WebAppService from "@/services/WebAppService";
import AuthStatusModal from "@/components/modals/AuthStatusModal";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import TermsModal from "@/components/modals/TermsModal";
import PrivacyModal from "@/components/modals/PrivacyModal";
import InputField5 from "@/components/ui/InputField5";

export default function SignUpForm() {
	const [formData, setFormData] = useState({
		email: "",
		organization_name: "",
		first_name: "",
		last_name: "",
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formValid, setFormValid] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [showStatusModal, setShowStatusModal] = useState(false);
	const [showTerms, setShowTerms] = useState(false);
	const [showPolicy, setShowPolicy] = useState(false);
	const [buttonSize, setButtonSize] = useState("md");
	const navigate = useNavigate();

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	const openTerms = () => {
		setShowTerms(true);
	};

	const closeTerms = () => {
		setShowTerms(false);
	};
	const openPolicy = () => {
		setShowPolicy(true);
	};

	const closePolicy = () => {
		setShowPolicy(false);
	};

	useEffect(() => {
		const { email, organization_name, first_name, last_name } = formData;

		// Email validation regex
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		const isValid =
			first_name.trim() !== "" &&
			last_name.trim() !== "" &&
			email.trim() !== "" &&
			organization_name.trim() !== "" &&
			emailRegex.test(email); // Check if email matches regex

		setFormValid(isValid);
	}, [formData]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsSubmitting(true);

		try {
			// Make API call to sign up
			await WebAppService.signUp(formData);

			// Reset form fields after successful submission
			setFormData({
				email: "",
				organization_name: "",
				first_name: "",
				last_name: "",
			});

			// Show success status modal or any other feedback
			setIsSuccess(true);
			setShowStatusModal(true);
			setTimeout(() => {
				setShowStatusModal(false);
				navigate("/login");
			}, 3000);
		} catch (error) {
			// Handle error
			console.error("Error during sign up:", error);

			// Check if error response exists
			if (error.response.status === 409) {
				// Extract error message from response data
				const signUpErrorMessage =
					error.response.data.error || "Something went wrong. Please retry.";

				// Set error message state
				setErrorMessage(signUpErrorMessage);
				setIsSuccess(false);
				setShowStatusModal(true);
				setTimeout(() => {
					setShowStatusModal(false);
				}, 3000);
			} else {
				// If no response is received, set a generic error message
				setErrorMessage("Something went wrong. Please retry.");
				setIsSuccess(false);
				setShowStatusModal(true);
				setTimeout(() => {
					setShowStatusModal(false);
				}, 3000);
			}

			// Show error status modal or any other feedback
			setIsSuccess(false);
			setShowStatusModal(true);
			setTimeout(() => {
				setShowStatusModal(false);
			}, 3000);
		}

		setIsSubmitting(false);
	};

	useEffect(() => {
		// Determine the screen size and set button size accordingly
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setButtonSize("sm");
			} else if (window.innerWidth > 1024) {
				setButtonSize("lg");
			} else {
				setButtonSize("md");
			}
		};

		// Initial call to set the button size
		handleResize();

		// Event listener for window resize
		window.addEventListener("resize", handleResize);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			<div className="flex m-auto items-center justify-center w-full">
				<div className="grid sm:flex sm:flex-col md:flex-col lg:flex-row items-center gap-x-[120px] gap-y-2 justify-center w-full mb-[50px] md:mb-[50px] lg:mb[0px]">
					<div className="mt-[50px] md:mt-[50px] lg:mt[0px]  w-full lg:w-2/5 flex items-center flex-col-reverse md:flex-col-reverse lg:flex-col">
						<img
							className="w-[60%] md:w-[60%] lg:w-full"
							src={signupsvg}
							alt="signupsvg"
							loading="lazy"
						/>

						<div className="w-full md:w-[80%] lg:w-full text-center flex flex-col gap-3 items-center">
							<h1 className="text-center text-white-1 font-semibold text-[18px] md:text-[20px] lg:text-[24px]">
								AI-Powered Task Automation
							</h1>
							<p className="mt-2 text-[14px] md:text-[16px] lg:text-[16px]">
								We automate mundane tasks and streamline workflows, freeing your
								team to focus on building great products.
							</p>
						</div>
					</div>

					<div className="border border-black-1 h-fit px-5 py-10 lg:py-10 lg:px-10 w-full lg:w-[45%] rounded-lg fullBorder">
						<div className="flex flex-col gap-7">
							<div className="mx-auto w-4/5 lg:w-full flex flex-col gap-2">
								<h1 className="text-center lg:text-left text-white-1 font-semibold text-[18px] md:text-[20px] lg:text-[24px]">
									Create your account
								</h1>
								<p className="text-center lg:text-left text-[14px] md:text-[16px] lg:text-[16px]">
									Join thousands of engineering and product teams experiencing
									the Crafter advantage.
								</p>
							</div>
							<form
								className="w-full flex flex-col gap-6"
								onSubmit={handleSubmit}>
								<div className="w-full flex flex-col gap-6">
									<div className="flex flex-col gap-x-3 gap-y-6 lg:flex-row">
										<InputField
											name={"first_name"}
											label={"First Name"}
											type={"text"}
											placeholder={"First name"}
											isRequired={true}
											value={formData.first_name}
											onChange={handleInputChange}
										/>
										<InputField
											name={"last_name"}
											label={"Last Name"}
											type={"text"}
											placeholder={"Last name"}
											isRequired={true}
											value={formData.last_name}
											onChange={handleInputChange}
										/>
									</div>

									<InputField5
										type="email"
										name="email"
										label="Company Email"
										placeholder="Input your company email"
										isRequired={true}
										value={formData.email}
										onChange={handleInputChange}
										message="Kindly note that to successfully create an account, you must use a work email (not gmail, outlook or yahoo mail)"
									/>
									<InputField
										name={"organization_name"}
										label={"Company Name"}
										placeholder={"Input your company name"}
										isRequired={true}
										value={formData.organization_name}
										onChange={handleInputChange}
									/>
								</div>
								<GlobalButton
									variant={"secondary"}
									size={buttonSize}
									state={
										isSubmitting
											? "inactive"
											: formValid
											? "default"
											: "inactive"
									}
									type="submit"
									disabled={!formValid || isSubmitting}>
									{isSubmitting ? "Submitting..." : "Submit"}
								</GlobalButton>
							</form>

							<p className="text-center text-[12px] md:text-[14px] lg:text-[16px]">
								Already have an account?{" "}
								<Link
									className={`text-blue-2 underline underline-offset-2`}
									to={"/login"}>
									Log in
								</Link>
							</p>

							<small className="w-[90%] lg:w-full text-center text-[12px] md:text-[14px] lg:text-[16px]">
								By creating an account you accept the{" "}
								<span
									onClick={openTerms}
									className="cursor-pointer text-blue-4 font-medium underline-offset-2 underline">
									Terms of service
								</span>{" "}
								and{" "}
								<span
									onClick={openPolicy}
									className="cursor-pointer text-blue-4 font-medium underline-offset-2 underline">
									Privacy Policy
								</span>
							</small>
						</div>
					</div>
				</div>
			</div>
			{showStatusModal && (
				<AuthStatusModal
					isSuccess={isSuccess}
					errorMessage={errorMessage}
				/>
			)}

			{showTerms && <TermsModal onClose={closeTerms} />}
			{showPolicy && <PrivacyModal onClose={closePolicy} />}
		</>
	);
}
