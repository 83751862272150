import React from "react";

export default function NotificationsModal({ onClose }) {
	return (
		<div className="modal">
			<div
				onClick={onClose}
				className="notification-modal-overlay"></div>
			<div className="notificationBg notification-modal-container  h-[500px] p-10 w-[30%] absolute right-10 top-[90px] border justify-center flex border-gray-2/50 rounded-md">
				<h1>No new notifications</h1>
			</div>
		</div>
	);
}
