import React from "react";

export default function StatusModal({ isSuccess, errorMessage }) {
	return (
		<div className="modal-left ">
			<div className="modal-overlay absolute inset-0  opacity-100 "></div>
			<div className="modal-container-status bg-black-2 flex gap-5 lg:gap-10 md:gap-10 z-40 py-10 px-8 lg:px-12 md:px-12 w-[80%] md:w-[80%] lg:w-[50%] rounded-lg shadow-lg border border-gray-5">
				{isSuccess ? (
					<svg
						width="43"
						height="43"
						viewBox="0 0 43 43"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							opacity="0.4"
							d="M21.5 39.4167C31.3951 39.4167 39.4167 31.3951 39.4167 21.5C39.4167 11.6049 31.3951 3.58337 21.5 3.58337C11.6049 3.58337 3.58337 11.6049 3.58337 21.5C3.58337 31.3951 11.6049 39.4167 21.5 39.4167Z"
							fill="#63B575"
						/>
						<path
							d="M18.9558 27.9142C18.5975 27.9142 18.2571 27.7708 18.0062 27.52L12.9358 22.4496C12.4162 21.93 12.4162 21.07 12.9358 20.5504C13.4554 20.0308 14.3154 20.0308 14.835 20.5504L18.9558 24.6712L28.165 15.4621C28.6846 14.9425 29.5446 14.9425 30.0642 15.4621C30.5837 15.9817 30.5837 16.8417 30.0642 17.3612L19.9054 27.52C19.6546 27.7708 19.3142 27.9142 18.9558 27.9142Z"
							fill="#63B575"
						/>
					</svg>
				) : (
					<svg
						width="40"
						height="40"
						viewBox="0 0 40 40"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							opacity="0.4"
							d="M20 36.6667C29.2048 36.6667 36.6667 29.2048 36.6667 20C36.6667 10.7953 29.2048 3.33337 20 3.33337C10.7953 3.33337 3.33337 10.7953 3.33337 20C3.33337 29.2048 10.7953 36.6667 20 36.6667Z"
							fill="#FF7272"
						/>
						<path
							d="M21.7666 20L25.6 16.1666C26.0833 15.6833 26.0833 14.8833 25.6 14.4C25.1166 13.9166 24.3166 13.9166 23.8333 14.4L20 18.2333L16.1666 14.4C15.6833 13.9166 14.8833 13.9166 14.4 14.4C13.9166 14.8833 13.9166 15.6833 14.4 16.1666L18.2333 20L14.4 23.8333C13.9166 24.3166 13.9166 25.1166 14.4 25.6C14.65 25.85 14.9666 25.9666 15.2833 25.9666C15.6 25.9666 15.9166 25.85 16.1666 25.6L20 21.7666L23.8333 25.6C24.0833 25.85 24.4 25.9666 24.7166 25.9666C25.0333 25.9666 25.35 25.85 25.6 25.6C26.0833 25.1166 26.0833 24.3166 25.6 23.8333L21.7666 20Z"
							fill="#FF7272"
						/>
					</svg>
				)}
				<div className="flex flex-col items-start ">
					<h2 className="text-[18px] sm:text-2xl text-gradient-blue font-semibold text-left">
						{isSuccess
							? "Form submission successful"
							: "Form submission failed"}
					</h2>
					<p className="text-gray-4 text-left mt-2">
						{isSuccess
							? "Hi there! Thank you for your submission. We will get back to you as soon as we can."
							: errorMessage}
					</p>
				</div>
			</div>
		</div>
	);
}
