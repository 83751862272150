import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProjectBody from "./ProjectBody";
import WebAppService from "@/services/WebAppService";
import TicketBody2 from "../ticketPage/TicketBody2";
import ErrorToast from "@/components/modals/ErrorToast";

export default function ProjectPage() {
	const { projectId } = useParams();
	const [collapsed, setCollapsed] = useState(false);
	const [showErrorToast, setShowErrorToast] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [projectDetails, setProjectDetails] = useState(null);
	const [activeComponent, setActiveComponent] = useState("ProjectBody");
	const [clickedTicketIndex, setClickedTicketIndex] = useState(null);
	const [loading, setLoading] = useState(true);
	const [showTicketPage, setShowTicketPage] = useState(false);
	const [showProjectPage, setShowProjectPage] = useState(false);

	useEffect(() => {
		setLoading(true);
		// Fetch project details using the project ID from the URL
		WebAppService.getProjectDetails(projectId)
			.then((response) => {
				if (response) {
					setLoading(false);
				}
				setProjectDetails(response.data);
			})
			.catch((error) => {
				setLoading(false);

				if (error.response) {
					setErrorMessage(error.response?.data?.error);
					setTimeout(() => {
						setShowErrorToast(true);
					}, 500);
					setTimeout(() => {
						setShowErrorToast(false);
					}, 4000);
				} else {
					setErrorMessage("Something went wrong. Please try again");
					setTimeout(() => {
						setShowErrorToast(true);
					}, 500);
					setTimeout(() => {
						setShowErrorToast(false);
					}, 4000);
				}
			});
	}, [projectId]);

	const handleTicketClick = (index) => {
		setClickedTicketIndex(index); // Store the clicked index
		setShowTicketPage(true);
		setShowProjectPage(false);
		setActiveComponent("TicketBody");
	};

	const handleBack = () => {
		setShowTicketPage(false);
		setShowProjectPage(true);
		setActiveComponent("ProjectBody");
	};

	return (
		<main className="relative bg-black-6 text-gray-4 h-screen w-full flex flex-col">
			<div className="flex w-full h-full">
				<div
					className={`w-full ${
						collapsed ? "w-[95%]" : "w-[80%]"
					} overflow-scroll h-full`}>
					{activeComponent === "ProjectBody" && (
						<ProjectBody
							loading={loading}
							projectDetails={projectDetails}
							setShowTicketPage={handleTicketClick}
						/>
					)}
					{activeComponent === "TicketBody" && (
						<TicketBody2
							loading={loading}
							clickedTicketIndex={clickedTicketIndex}
							projectDetails={projectDetails}
							setShowProjectPage={handleBack}
						/>
					)}
				</div>
			</div>

			{showErrorToast && <ErrorToast message={errorMessage} />}
		</main>
	);
}
