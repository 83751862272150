import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import useAuthStore from "@/store/Auth";
import AccessRequest from "./AccessRequest";
import AlreadyConfigured from "./AlreadyConfigured";
import JiraIntegrationForm from "./JiraIntegrationForm";
import { useNavigate } from "react-router-dom";

export default function Jira() {
	const [collapsed, setCollapsed] = useState(false);
	const [activeComponent, setActiveComponent] = useState("");
	const navigate = useNavigate();
	const authStore = useAuthStore();

	const hasSetJiraConfig = authStore.organization.hasSetJiraConfig;

	const handleRequestGranted = () => {
		setActiveComponent("JiraIntegrationForm");
	};

	const handleCancel = () => {
		navigate("/integrations");
	};

	useEffect(() => {
		if (hasSetJiraConfig) {
			setActiveComponent("AlreadyConfigured");
		} else {
			setActiveComponent("AccessRequest");
		}
	}, [hasSetJiraConfig]);

	return (
		<main className="relative bg-black-6 text-gray-4 h-screen overflow-hidden w-full flex flex-col">
			<Helmet>
				<title>Jira | Crafter</title>
			</Helmet>
			<div className="flex w-full h-full">
				<div
					className={`w-full ${
						collapsed ? "w-[95%]" : "w-[80%]"
					} overflow-scroll h-screen`}>
					{activeComponent === "AccessRequest" && (
						<AccessRequest
							onCancel={handleCancel}
							onContinue={handleRequestGranted}
						/>
					)}
					{activeComponent === "AlreadyConfigured" && (
						<AlreadyConfigured onCancel={handleCancel} />
					)}
					{activeComponent === "JiraIntegrationForm" && (
						<JiraIntegrationForm onCancel={handleCancel} />
					)}
				</div>
			</div>
		</main>
	);
}
