import React from "react";
import GlobalButton from "@/components/ui/GlobalButton";
import { Link } from "react-router-dom";

export default function JiraConfigFailed({ onClose }) {
	return (
		<div className="modal">
			<div
				onClick={onClose}
				className="modal-overlay absolute inset-0 bg-black-1/40 opacity-90"></div>

			<div className="modalBg w-[90%] md:w-[90%] lg:w-[35%] xl:max-w-[700px] max-h-[90%] px-8 py-10 h-fit top-1/2 left-1/2 absolute  transform -translate-x-1/2 -translate-y-1/2  flex flex-col gap-5 items-center rounded-md shadow-md text-white-1">
				<div className="w-full flex flex-col gap-3 items-center">
					<h1 className="font-semibold text-[20px]">
						Account integration failed
					</h1>
					<p className="text-gray-4 text-center">
						Looks like we hit an iceberg there, please try integrating your Jira
						account again
					</p>
				</div>
				<div className="w-full mt-3">
					<GlobalButton
						onClick={onClose}
						variant={"blue"}
						state={"default"}
						size={"lg"}>
						Integrate your Jira
					</GlobalButton>
				</div>
				<div>
					<p>
						Need help?{" "}
						<Link
							to={"https://usecrafter.com/contact"}
							target="_blank"
							className="text-orange-1 underline underline-offset-4">
							Contact us
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
}
