import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function PendingProjects({ onClose, sentProjects }) {
	// useEffect(() => {
	// 	console.log(sentProjects);
	// }, [sentProjects]);

	const bytesToKB = (bytes) => {
		return (bytes / 1024).toFixed(2);
	};
	return (
		<div className="modal">
			<div
				onClick={onClose}
				className="notification-modal-overlay"></div>
			<div className="notificationBg notification-modal-container  h-fit gap-3 flex-col py-5 px-5 w-[30%] absolute right-10 top-[90px] border justify-center flex border-gray-2/50 rounded-md">
				{sentProjects.length === 0 ? (
					<p className="text-center mt-4">No pending projects</p>
				) : (
					<>
						{sentProjects.map((sentProject, index) => (
							<div
								key={index}
								className="flex gap-3 h-fit items-start justify-between w-full">
								<div className="w-4/5">
									<div>
										<h1 className="text-lg font-semibold text-white-1">
											{sentProject?.name}
										</h1>
										<p className="text-sm">
											{sentProject?.file?.name || (
												<Link
													className="text-blue-2 text-xs"
													to={sentProject?.googleDocLink}
													target="_blank">
													View
												</Link>
											)}
										</p>
										<small className="text-orange-1 text-xs">
											Your tickets are being generated
										</small>
									</div>

									<div className="mt-2 w-full flex flex-col gap-1">
										<div className="bg-gray-2 rounded-sm opacity-animation h-[7px] w-3/5"></div>
										<div className="bg-gray-2 rounded-sm opacity-animation h-[9px] w-full"></div>
									</div>
								</div>
								<small className="text-xs text-blue-4 font-semibold">
									{sentProject?.file?.size
										? `${bytesToKB(sentProject?.file?.size)} KB`
										: ""}
								</small>
							</div>
						))}
					</>
				)}
			</div>
		</div>
	);
}
