export const formatDatesDmy = (dateString) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const d = new Date(dateString);
    const day = d.getDate();
    const daySuffix = getDaySuffix(day);
    const month = months[d.getMonth()];
    const year = d.getFullYear();

    return `${day}${daySuffix}, ${month} ${year}`;
};

const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};