import React from "react";

export default function LogoutIcon() {
	return (
		<>
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.41797 6.29995C7.6763 3.29995 9.21797 2.07495 12.593 2.07495H12.7013C16.4263 2.07495 17.918 3.56662 17.918 7.29162V12.725C17.918 16.45 16.4263 17.9416 12.7013 17.9416H12.593C9.24297 17.9416 7.7013 16.7333 7.4263 13.7833"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M12.5009 10H3.01758"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M4.87565 7.20825L2.08398 9.99992L4.87565 12.7916"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</>
	);
}
