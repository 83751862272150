import React from "react";

export default function UpLoad() {
	return (
		<svg
			width="51"
			height="50"
			viewBox="0 0 51 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28.5624 29.9795H22.4374C19.7916 29.9795 18.2083 31.5628 18.2083 34.2087V40.3337C18.2083 42.9795 19.7916 44.5628 22.4374 44.5628H28.5624C31.2083 44.5628 32.7916 42.9795 32.7916 40.3337V34.2087C32.7916 31.5628 31.2083 29.9795 28.5624 29.9795ZM30.5208 38.167C30.3124 38.3753 29.9999 38.5212 29.6666 38.542H26.7291L26.7499 41.4378C26.7291 41.792 26.6041 42.0837 26.3541 42.3337C26.1458 42.542 25.8333 42.6878 25.4999 42.6878C24.8124 42.6878 24.2499 42.1253 24.2499 41.4378V38.5212L21.3333 38.542C20.6458 38.542 20.0833 37.9587 20.0833 37.2712C20.0833 36.5837 20.6458 36.0212 21.3333 36.0212L24.2499 36.042V33.1253C24.2499 32.4378 24.8124 31.8545 25.4999 31.8545C26.1874 31.8545 26.7499 32.4378 26.7499 33.1253L26.7291 36.0212H29.6666C30.3541 36.0212 30.9166 36.5837 30.9166 37.2712C30.8958 37.6253 30.7499 37.917 30.5208 38.167Z"
				fill="#F58E30"
			/>
			<path
				d="M45.7918 24.4582C44.5209 20.2915 41.3543 17.2915 37.3751 16.3957C36.2084 11.1873 33.0001 7.45816 28.4584 6.04149C23.5001 4.52066 17.7501 5.99983 14.1459 9.77066C10.9584 13.0832 9.91675 17.6457 11.1459 22.4998C6.70841 23.5832 4.66675 27.4582 4.66675 30.9582C4.66675 34.8748 7.22925 39.2707 12.9376 39.6665H18.2084V34.1873C18.2084 31.5415 19.7917 29.9582 22.4376 29.9582H28.5626C31.2084 29.9582 32.7917 31.5415 32.7917 34.1873V39.6665H34.4792C34.5001 39.6665 34.5418 39.6665 34.5626 39.6665C37.5209 39.6665 40.3543 38.5623 42.5209 36.5832C45.9168 33.6248 47.1668 28.979 45.7918 24.4582Z"
				fill="#A6A6A6"
			/>
		</svg>
	);
}
