import React from "react";
import DropdownArrow from "@/icons/DropdownArrow";
import Craft from "@/icons/Craft";

export default function Polling() {
	return (
		<div className="fullBorder px-3 py-[6px]">
			<div className="flex items-center w-full gap-2">
				<div className="glowBorder2 ">
					<div className="pollingBtn  rounded-full w-[35px] h-[35px] flex items-center justify-center">
						<span className=" text-white-1 font-semibold text-sm">
							<Craft />
						</span>
					</div>
				</div>
				<div className="text-gray-4">
					<DropdownArrow />
				</div>
			</div>
		</div>
	);
}
