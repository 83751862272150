import React, {
	createContext,
	useState,
	useEffect,
	useCallback,
	useContext,
} from "react";
import WebAppService from "@/services/WebAppService";
import { useProjects } from "./ProjectsContext";
import { useToast } from "./ToastContext";

const PollingContext = createContext();

export const PollingProvider = ({ children }) => {
	const [isChecking, setIsChecking] = useState(false);
	const [startPolling, setStartPolling] = useState(false);
	const [createTicketsResponse, setCreateTicketsResponse] = useState(null);
	const [successToast, setSuccessToast] = useState(false);
	const [errorToast, setErrorToast] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [backoffDelay, setBackoffDelay] = useState(1000);
	const [sentProjects, setSentProjects] = useState([]);
	const { refetchProjects } = useProjects();
	const { addToast } = useToast();
	let noTasksCount = 0;

	const startPollingTicketsStatus = useCallback(() => {
		setIsChecking(true);

		setTimeout(() => {
			if (
				createTicketsResponse?.message === "Tickets already exist for this file"
			) {
				setStartPolling(false);
				setSentProjects([]);
				setIsChecking(false);
				// setSuccessMessage("Tickets already exist for this file");
				// setSuccessToast(true);
				addToast("Tickets already exist for this file", "error", 3000);

				setTimeout(() => {
					setCreateTicketsResponse(null);
					// setSuccessToast(false);
				}, 2000);
			} else {
				setStartPolling(true);
			}
		}, 2000);
	}, [createTicketsResponse]);

	useEffect(() => {
		if (createTicketsResponse !== null) {
			startPollingTicketsStatus();
		}
	}, [createTicketsResponse, startPollingTicketsStatus]);

	const checkTicketsStatusWithBackoff = useCallback(async () => {
		if (!startPolling || !isChecking) {
			return;
		}
		try {
			const getTasksResponse = await WebAppService.getAllTasks();

			if (getTasksResponse?.data[0]?.tasks?.length > 0) {
				setIsChecking(false);
				setSentProjects([]);
				setStartPolling(false);
				// setSuccessMessage("Tickets generated successfully");
				// setSuccessToast(true);
				addToast("Tickets generated successfully", "success", 3000);

				refetchProjects();
				setTimeout(() => {
					// setSuccessToast(false);
				}, 3000);
			} else {
				noTasksCount++;
				if (noTasksCount >= 15) {
					setIsChecking(false);
					setSentProjects([]);
					setStartPolling(false);

					addToast("No tasks found after multiple checks", "error", 3000);
					// setErrorMessage("No tasks found after multiple checks");
					// setErrorToast(true);

					// setTimeout(() => {
					// 	setErrorToast(false);
					// }, 3000);
				}
			}
		} catch (error) {
			console.error("Error checking tickets status:", error);
			setIsChecking(false);
			setSentProjects([]);
			setStartPolling(false);

			addToast("Something went wrong", "error", 3000);
			// setErrorMessage("Something went wrong");
			// setErrorToast(true);
			setBackoffDelay(1000);

			// setTimeout(() => {
			// 	setErrorToast(false);
			// }, 3000);

			if (backoffDelay < 30000) {
				setBackoffDelay((prevDelay) => prevDelay * 2);

				setTimeout(() => {
					checkTicketsStatusWithBackoff();
				}, backoffDelay);
			} else {
				setStartPolling(false);
				console.error("Maximum retry limit reached.");

				addToast("Maximum retry limit reached.", "warning", 3000);
				// setErrorMessage("Maximum retry limit reached.");
				// setErrorToast(true);
			}
		}
	}, [backoffDelay, isChecking, startPolling, noTasksCount, refetchProjects]);

	useEffect(() => {
		if (startPolling && isChecking) {
			const intervalId = setInterval(() => {
				checkTicketsStatusWithBackoff();
			}, 5000);

			return () => {
				clearInterval(intervalId);
			};
		}
	}, [checkTicketsStatusWithBackoff, isChecking, startPolling, backoffDelay]);

	return (
		<PollingContext.Provider
			value={{
				isChecking,
				startPollingTicketsStatus,
				setCreateTicketsResponse,
				// successToast,
				// errorToast,
				// successMessage,
				// errorMessage,
				sentProjects,
				setSentProjects,
				createTicketsResponse,
				setIsChecking,
				backoffDelay,
				setBackoffDelay,
			}}>
			{children}
		</PollingContext.Provider>
	);
};

export const usePolling = () => useContext(PollingContext);
