import { useLocation, useParams } from "react-router-dom";
import Heading from "./components/Heading";
import Sidebar from "./components/Sidebar";
import { usePlans } from "./context/PlansContext";
import { Outlet } from "react-router-dom";
import NotFound from "./pages/notFound/NotFound";
import { useEffect, useState } from "react";
import LoadingComponent from "@/components/LoadingComponent";
import LogoutModal from "./components/modals/LogoutModal";

function Layout({
	children,
	createTicketsResponse,
	sentProjects,
	setSentProjects,
	isChecking,
	setIsChecking,
	openPendingProjects,
	setOpenPendingProjects,
	collapsed,
	setCollapsed,
}) {
	const location = useLocation();
	const params = useParams();
	const [isExistingRoute, setIsExistingRoute] = useState(false);
	const [confirmLogout, setConfirmLogout] = useState(false);
	const [loading, setLoading] = useState(true); // Add loading state
	const { isSubscribed, subscription, hasSubscriptionEnded } = usePlans();

	const isAuthPage =
		location.pathname === "/login" || location.pathname === "/signup";

	//if you create a new route, you should add it to the "routes" array below
	const routes = [
		{ name: "home", link: "/" },
		{ name: "login", link: "/login" },
		{ name: "signup", link: "/signup" },
		{ name: "projects", link: "/projects" },
		{ name: "projects", link: "/projects/:projectId" },
		{ name: "integrations", link: "/integrations" },
		{ name: "integrations", link: "/integrations/Jira" },
		{ name: "integrations", link: "/integrations/Jira" },
		{ name: "plans", link: "/plans" },
	];

	useEffect(() => {
		const checkExistingRoute = () => {
			// Check if the current path is in the predefined routes
			const existingRoute = routes.some(
				(route) => location.pathname === route.link
			);

			// Check dynamic routes using params
			const dynamicRoute = routes.some((route) => {
				const regex = new RegExp(`^${route.link.replace(/:\w+/g, "\\w+")}$`);
				return regex.test(location.pathname);
			});

			setIsExistingRoute(existingRoute || dynamicRoute);
			setLoading(false); // Set loading to false after the route check is complete
		};

		checkExistingRoute();
	}, [location, params]);

	if (isAuthPage) {
		return <>{children}</>;
	}

	if (loading) {
		return (
			<div className="h-screen bg-black-6">
				<LoadingComponent />
			</div>
		);
	}

	const handleCloseLogoutModal = () => {
		setConfirmLogout(false);
	};

	return (
		<>
			{isExistingRoute ? (
				<>
					<div className="relative bg-black-6 text-gray-4 h-screen w-full flex flex-col">
						{/* Header */}
						<div className="bg-black-6 z-20 absolute top-0 w-full flex items-center justify-between py-4 h-[80px] px-[20px] lg:px-[20px] md:px-[20px] border-b-0.5 border-gray-2">
							<Heading
								createTicketsResponse={createTicketsResponse}
								sentProjects={sentProjects}
								setSentProjects={setSentProjects}
								isChecking={isChecking}
								setIsChecking={setIsChecking}
								openPendingProjects={openPendingProjects}
								setOpenPendingProjects={setOpenPendingProjects}
								isSubscribed={isSubscribed}
								subscription={subscription}
								hasSubscriptionEnded={hasSubscriptionEnded}
							/>
						</div>

						{/* Main Content */}
						<div className="flex flex-1 overflow-hidden">
							{/* Sidebar */}
							<div
								className={`${
									collapsed ? "sidebarWidth1 w-[5%]" : "sidebarWidth2 w-[17%]"
								} transition-width duration-500 h-full`}>
								<Sidebar
									collapsed={collapsed}
									setCollapsed={setCollapsed}
									setConfirmLogout={setConfirmLogout}
								/>
							</div>

							{/* Main Content */}
							<div
								className={`${
									collapsed ? "w-[95%]" : "w-[83%]"
								} overflow-hidden h-full`}>
								{children}
							</div>
						</div>
					</div>
					<Outlet />
				</>
			) : (
				<NotFound />
			)}

			{confirmLogout && <LogoutModal onClose={handleCloseLogoutModal} />}
		</>
	);
}

export default Layout;
