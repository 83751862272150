import React, { useState } from "react";
import LiteCard from "./LiteCard";
import ProCard from "./ProCard";
import FreeCard from "./FreeCard";

export default function Plan({ plans, onCheckout }) {
	const [activePeriod, setActivePeriod] = useState("Monthly");
	const freePlan = plans[1];
	const litePlan = plans[0];

	const handlePlanClick = (productId) => {
		if (onCheckout) {
			onCheckout(productId);
		}
	};

	return (
		<div
			id="container"
			className="flex bg3 flex-col gap-8 w-full h-full p-8 mt-8 border border-gray-2/30 rounded-md overflow-scroll innerFrameBg">
			<div>
				<h1 className="font-semibold text-[20px]">Subscriptions</h1>
			</div>

			<div className="py-[18px] w-full flex justify-center gap-4 rounded-lg">
				<button
					className={`fullBorder px-4 py-2 font-semibold text-center cursor-pointer ${
						activePeriod === "Monthly" ? "" : "opacity-[20%]"
					}`}
					onClick={() => setActivePeriod("Monthly")}>
					Monthly
				</button>
				<button
					disabled
					className={`fullBorder px-4 py-2 font-semibold text-center cursor-not-allowed ${
						activePeriod === "Annually" ? "" : "opacity-[20%]"
					}`}
					onClick={() => setActivePeriod("Annually")}>
					Annually
				</button>
			</div>

			<div className="flex items-center justify-center mx-auto gap-10 w-full h-fit">
				{/* <div className="w-full h-full">
					<FreeCard
						activePeriod={activePeriod}
						freePlan={freePlan}
					/>
				</div> */}
				<div className="w-full h-full">
					<LiteCard
						activePeriod={activePeriod}
						litePlan={litePlan}
						onPlanSelect={handlePlanClick}
					/>
				</div>
				<div className="w-full h-full">
					<ProCard activePeriod={activePeriod} />
				</div>
			</div>
		</div>
	);
}
