import React from "react";

export default function MoreIcon({ onClick, className, clicked }) {
	return (
		<svg
			className={className}
			onClick={onClick}
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.16667 8.33301C3.25 8.33301 2.5 9.08301 2.5 9.99967C2.5 10.9163 3.25 11.6663 4.16667 11.6663C5.08333 11.6663 5.83333 10.9163 5.83333 9.99967C5.83333 9.08301 5.08333 8.33301 4.16667 8.33301Z"
				stroke={`${clicked ? "#A6A6A6" : "currentColor"}`}
				stroke-width="1.5"
			/>
			<path
				d="M15.8333 8.33301C14.9167 8.33301 14.1667 9.08301 14.1667 9.99967C14.1667 10.9163 14.9167 11.6663 15.8333 11.6663C16.75 11.6663 17.5 10.9163 17.5 9.99967C17.5 9.08301 16.75 8.33301 15.8333 8.33301Z"
				stroke={`${clicked ? "#A6A6A6" : "currentColor"}`}
				stroke-width="1.5"
			/>
			<path
				d="M10 8.33301C9.08334 8.33301 8.33334 9.08301 8.33334 9.99967C8.33334 10.9163 9.08334 11.6663 10 11.6663C10.9167 11.6663 11.6667 10.9163 11.6667 9.99967C11.6667 9.08301 10.9167 8.33301 10 8.33301Z"
				stroke={`${clicked ? "#A6A6A6" : "currentColor"}`}
				stroke-width="1.5"
			/>
		</svg>
	);
}
