import React from "react";

export default function Uploaad() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.76685 5.41654L9.90018 3.2832L12.0335 5.41654"
				stroke="#B9B9B9"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.8999 11.8168V3.3418"
				stroke="#B9B9B9"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.33325 10C3.33325 13.6833 5.83325 16.6667 9.99992 16.6667C14.1666 16.6667 16.6666 13.6833 16.6666 10"
				stroke="#B9B9B9"
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
