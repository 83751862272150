import React from "react";

export default function TextArea({
	label,
	type,
	id,
	name,
	value,
	onChange,
	placeholder,
	isRequired,
}) {
	return (
		<div className="w-full flex flex-col items-start gap-2">
			<label className="text-sm text-white-1 font-semibold">
				{label} {isRequired && <span className="text-sm text-error-1">*</span>}
			</label>
			<textarea
				type={type}
				id={id}
				name={name}
				placeholder={placeholder}
				className="placeholder:text-gray-2 text-gray-4 w-full h-[200px] bg-transparent rounded-md py-[10px] px-[14px] border-1 border-black-1 focus:outline-none"
				value={value}
				onChange={onChange}
			/>
		</div>
	);
}
