import React, { useState } from "react";
import ClipboardIcon from "@/icons/ClipboardIcon";

export default function Clipboard({ text }) {
	const [success, setSuccess] = useState(false);

	const copyTextOnClick = async (text) => {
		try {
			await navigator.clipboard.writeText(text);

			setTimeout(() => {
				setSuccess(true);
			}, 300);
		} catch (error) {
			setSuccess(false);
		}
	};

	return (
		<div
			onClick={() => copyTextOnClick(text)}
			className="cursor-pointer flex justify-center mx-auto w-4/5 items-center gap-5 py-3 px-4 bg2 rounded-md border-0.5 border-blue-2">
			<div className="cursor-pointer bg-transparent w-full lg:text-[16px] xl:text-[16px] 2xl:text-[18px] text-center font-semibold">
				{text}
			</div>
			<div className="flex self-end justify-end">
				{success ? (
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
							stroke="#AAD5E3"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M7.75 11.9999L10.58 14.8299L16.25 9.16992"
							stroke="#F58E30"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				) : (
					<ClipboardIcon />
				)}
			</div>
		</div>
	);
}
