import React from "react";

export default function SuccessIcon() {
	return (
		<svg
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M32.3398 16.1098L30.2998 13.7398C29.9098 13.2898 29.5948 12.4498 29.5948 11.8498V9.29984C29.5948 7.70984 28.2898 6.40484 26.6998 6.40484H24.1498C23.5648 6.40484 22.7098 6.08984 22.2598 5.69984L19.8898 3.65984C18.8548 2.77484 17.1598 2.77484 16.1098 3.65984L13.7548 5.71484C13.3048 6.08984 12.4498 6.40484 11.8648 6.40484H9.26982C7.67982 6.40484 6.37482 7.70984 6.37482 9.29984V11.8648C6.37482 12.4498 6.05982 13.2898 5.68482 13.7398L3.65982 16.1248C2.78982 17.1598 2.78982 18.8398 3.65982 19.8748L5.68482 22.2598C6.05982 22.7098 6.37482 23.5498 6.37482 24.1348V26.6998C6.37482 28.2898 7.67982 29.5948 9.26982 29.5948H11.8648C12.4498 29.5948 13.3048 29.9098 13.7548 30.2998L16.1248 32.3398C17.1598 33.2248 18.8548 33.2248 19.9048 32.3398L22.2748 30.2998C22.7248 29.9098 23.5648 29.5948 24.1648 29.5948H26.7148C28.3048 29.5948 29.6098 28.2898 29.6098 26.6998V24.1498C29.6098 23.5648 29.9248 22.7098 30.3148 22.2598L32.3548 19.8898C33.2248 18.8548 33.2248 17.1448 32.3398 16.1098ZM24.2398 15.1648L16.9948 22.4098C16.7848 22.6198 16.4998 22.7398 16.1998 22.7398C15.8998 22.7398 15.6148 22.6198 15.4048 22.4098L11.7748 18.7798C11.3398 18.3448 11.3398 17.6248 11.7748 17.1898C12.2098 16.7548 12.9298 16.7548 13.3648 17.1898L16.1998 20.0248L22.6498 13.5748C23.0848 13.1398 23.8048 13.1398 24.2398 13.5748C24.6748 14.0098 24.6748 14.7298 24.2398 15.1648Z"
				fill="#86A16B"
			/>
		</svg>
	);
}
