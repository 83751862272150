import React from "react";

export default function ClipboardIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.8"
				d="M15.5 13.15H13.33C11.55 13.15 10.1 11.71 10.1 9.92V7.75C10.1 7.34 9.77 7 9.35 7H6.18C3.87 7 2 8.5 2 11.18V17.82C2 20.5 3.87 22 6.18 22H12.07C14.38 22 16.25 20.5 16.25 17.82V13.9C16.25 13.48 15.91 13.15 15.5 13.15Z"
				fill="#F0EEEE"
			/>
			<path
				d="M17.8198 2H15.8498H14.7598H11.9298C9.66977 2 7.83977 3.44 7.75977 6.01C7.81977 6.01 7.86977 6 7.92977 6H10.7598H11.8498H13.8198C16.1298 6 17.9998 7.5 17.9998 10.18V12.15V14.86V16.83C17.9998 16.89 17.9898 16.94 17.9898 16.99C20.2198 16.92 21.9998 15.44 21.9998 12.83V10.86V8.15V6.18C21.9998 3.5 20.1298 2 17.8198 2Z"
				fill="#AAD5E3"
			/>
			<path
				d="M11.9796 7.14975C11.6696 6.83975 11.1396 7.04975 11.1396 7.47975V10.0998C11.1396 11.1998 12.0696 12.0998 13.2096 12.0998C13.9196 12.1098 14.9096 12.1098 15.7596 12.1098C16.1896 12.1098 16.4096 11.6098 16.1096 11.3098C15.0196 10.2198 13.0796 8.26975 11.9796 7.14975Z"
				fill="#F58E30"
			/>
		</svg>
	);
}
