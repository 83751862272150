import React from "react";

export default function GetHelpIcon({ active }) {
	return (
		<>
			{active ? (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M8.95742 2.0416C9.54076 1.54993 10.4824 1.54993 11.0491 2.0416L12.3658 3.1666C12.6158 3.37493 13.0908 3.54994 13.4241 3.54994H14.8408C15.7241 3.54994 16.4491 4.27493 16.4491 5.15827V6.57493C16.4491 6.90827 16.6241 7.37493 16.8324 7.62493L17.9574 8.9416C18.4491 9.52494 18.4491 10.4666 17.9574 11.0333L16.8324 12.3499C16.6241 12.5999 16.4491 13.0666 16.4491 13.3999V14.8166C16.4491 15.6999 15.7241 16.4249 14.8408 16.4249H13.4241C13.0908 16.4249 12.6241 16.5999 12.3741 16.8083L11.0574 17.9333C10.4741 18.4249 9.53242 18.4249 8.96576 17.9333L7.64909 16.8083C7.39909 16.5999 6.92409 16.4249 6.59909 16.4249H5.14076C4.25742 16.4249 3.53242 15.6999 3.53242 14.8166V13.3916C3.53242 13.0666 3.36576 12.5916 3.15742 12.3499L2.03242 11.0249C1.54909 10.4499 1.54909 9.5166 2.03242 8.9416L3.15742 7.6166C3.36576 7.3666 3.53242 6.89993 3.53242 6.57493V5.1666C3.53242 4.28327 4.25742 3.55827 5.14076 3.55827H6.58242C6.91576 3.55827 7.38242 3.38327 7.63242 3.17493L8.95742 2.0416Z"
						fill="#B9B9B9"
					/>
					<path
						d="M9.99935 13.5583C9.81716 13.5583 9.66602 13.4071 9.66602 13.2249C9.66602 13.0395 9.81208 12.8916 9.99935 12.8916C10.1815 12.8916 10.3327 13.0427 10.3327 13.2249C10.3327 13.4104 10.1866 13.5583 9.99935 13.5583Z"
						fill="#333333"
						stroke="#F58E30"
					/>
					<path
						d="M10 10.9332C9.96985 10.9332 9.93857 10.921 9.91293 10.8953C9.88728 10.8697 9.875 10.8384 9.875 10.8082V6.7749C9.875 6.74475 9.88728 6.71347 9.91293 6.68783C9.93857 6.66219 9.96985 6.6499 10 6.6499C10.0301 6.6499 10.0614 6.66219 10.0871 6.68783C10.1127 6.71347 10.125 6.74475 10.125 6.7749V10.7999C10.125 10.8824 10.0652 10.9332 10 10.9332Z"
						fill="#0182AB"
						stroke="#0182AB"
					/>
				</svg>
			) : (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M8.95742 2.0416C9.54076 1.54993 10.4824 1.54993 11.0491 2.0416L12.3658 3.1666C12.6158 3.37493 13.0908 3.54994 13.4241 3.54994H14.8408C15.7241 3.54994 16.4491 4.27493 16.4491 5.15827V6.57493C16.4491 6.90827 16.6241 7.37493 16.8324 7.62493L17.9574 8.9416C18.4491 9.52494 18.4491 10.4666 17.9574 11.0333L16.8324 12.3499C16.6241 12.5999 16.4491 13.0666 16.4491 13.3999V14.8166C16.4491 15.6999 15.7241 16.4249 14.8408 16.4249H13.4241C13.0908 16.4249 12.6241 16.5999 12.3741 16.8083L11.0574 17.9333C10.4741 18.4249 9.53242 18.4249 8.96576 17.9333L7.64909 16.8083C7.39909 16.5999 6.92409 16.4249 6.59909 16.4249H5.14076C4.25742 16.4249 3.53242 15.6999 3.53242 14.8166V13.3916C3.53242 13.0666 3.36576 12.5916 3.15742 12.3499L2.03242 11.0249C1.54909 10.4499 1.54909 9.5166 2.03242 8.9416L3.15742 7.6166C3.36576 7.3666 3.53242 6.89993 3.53242 6.57493V5.1666C3.53242 4.28327 4.25742 3.55827 5.14076 3.55827H6.58242C6.91576 3.55827 7.38242 3.38327 7.63242 3.17493L8.95742 2.0416Z"
						stroke="#A6A6A6"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M10 6.7749V10.7999"
						stroke="#A6A6A6"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M9.99609 13.3333H10.0036"
						stroke="#A6A6A6"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			)}
		</>
	);
}
