import React from "react";

export default function SlackIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.2">
				<path
					d="M5.06771 15.1677C5.06771 16.5609 3.94169 17.6869 2.54849 17.6869C1.15529 17.6869 0.029273 16.5609 0.029273 15.1677C0.029273 13.7745 1.15529 12.6484 2.54849 12.6484H5.06771V15.1677Z"
					fill="#E01E5A"
				/>
				<path
					d="M6.32627 15.1686C6.32627 13.7754 7.45228 12.6494 8.84549 12.6494C10.2387 12.6494 11.3647 13.7754 11.3647 15.1686V21.4667C11.3647 22.8599 10.2387 23.9859 8.84549 23.9859C7.45228 23.9859 6.32627 22.8599 6.32627 21.4667V15.1686Z"
					fill="#E01E5A"
				/>
				<path
					d="M8.84549 5.05308C7.45228 5.05308 6.32627 3.92707 6.32627 2.53387C6.32627 1.14066 7.45228 0.0146484 8.84549 0.0146484C10.2387 0.0146484 11.3647 1.14066 11.3647 2.53387V5.05308H8.84549Z"
					fill="#36C5F0"
				/>
				<path
					d="M8.84611 6.33203C10.2393 6.33203 11.3653 7.45805 11.3653 8.85125C11.3653 10.2445 10.2393 11.3705 8.84611 11.3705H2.52898C1.13578 11.3705 0.00976562 10.2445 0.00976562 8.85125C0.00976562 7.45805 1.13578 6.33203 2.52898 6.33203H8.84611Z"
					fill="#36C5F0"
				/>
				<path
					d="M18.9414 8.85125C18.9414 7.45805 20.0674 6.33203 21.4606 6.33203C22.8538 6.33203 23.9798 7.45805 23.9798 8.85125C23.9798 10.2445 22.8538 11.3705 21.4606 11.3705H18.9414V8.85125Z"
					fill="#2EB67D"
				/>
				<path
					d="M17.683 8.85099C17.683 10.2442 16.557 11.3702 15.1637 11.3702C13.7705 11.3702 12.6445 10.2442 12.6445 8.85099V2.53387C12.6445 1.14066 13.7705 0.0146484 15.1637 0.0146484C16.557 0.0146484 17.683 1.14066 17.683 2.53387V8.85099Z"
					fill="#2EB67D"
				/>
				<path
					d="M15.1637 18.9473C16.557 18.9473 17.683 20.0733 17.683 21.4665C17.683 22.8597 16.557 23.9857 15.1637 23.9857C13.7705 23.9857 12.6445 22.8597 12.6445 21.4665V18.9473H15.1637Z"
					fill="#ECB22E"
				/>
				<path
					d="M15.1638 17.6869C13.7705 17.6869 12.6445 16.5609 12.6445 15.1677C12.6445 13.7745 13.7705 12.6484 15.1638 12.6484H21.4809C22.8741 12.6484 24.0001 13.7745 24.0001 15.1677C24.0001 16.5609 22.8741 17.6869 21.4809 17.6869H15.1638Z"
					fill="#ECB22E"
				/>
			</g>
		</svg>
	);
}
