import React from "react";

export default function BellIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.8931 9.66026L12.2265 8.55359C12.0865 8.30693 11.9598 7.84026 11.9598 7.56693V5.88026C11.9598 3.70693 10.1931 1.93359 8.01313 1.93359C5.83313 1.93359 4.06647 3.70693 4.06647 5.88026V7.56693C4.06647 7.84026 3.9398 8.30693 3.7998 8.54693L3.12647 9.66026C2.8598 10.1069 2.7998 10.6003 2.96647 11.0536C3.12647 11.5003 3.50647 11.8469 3.9998 12.0136C5.29313 12.4536 6.65313 12.6669 8.01313 12.6669C9.37313 12.6669 10.7331 12.4536 12.0265 12.0203C12.4931 11.8669 12.8531 11.5136 13.0265 11.0536C13.1998 10.5936 13.1531 10.0869 12.8931 9.66026Z"
				fill="#D4D4D4"
			/>
			<path
				d="M9.49987 2.21301C9.03987 2.03301 8.53987 1.93301 8.0132 1.93301C7.4932 1.93301 6.9932 2.02634 6.5332 2.21301C6.81987 1.67301 7.38654 1.33301 8.0132 1.33301C8.64654 1.33301 9.20654 1.67301 9.49987 2.21301Z"
				fill="#F58E30"
			/>
			<path
				d="M9.8863 13.3397C9.6063 14.113 8.8663 14.6663 7.99963 14.6663C7.47296 14.6663 6.95296 14.453 6.5863 14.073C6.37296 13.873 6.21296 13.6063 6.11963 13.333C6.2063 13.3463 6.29296 13.353 6.3863 13.3663C6.53963 13.3863 6.69963 13.4063 6.85963 13.4197C7.23963 13.453 7.6263 13.473 8.01296 13.473C8.39296 13.473 8.77296 13.453 9.1463 13.4197C9.2863 13.4063 9.4263 13.3997 9.55963 13.3797C9.6663 13.3663 9.77296 13.353 9.8863 13.3397Z"
				fill="#F58E30"
			/>
		</svg>
	);
}
